import React, {ReactNode} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface Props {
  isOpen?: boolean;
  children: ReactNode;
  dismiss: () => void;
}

function SideBarFlyoutWithInputs(props: Props) {
  const {isOpen, children, dismiss} = props;

  return isOpen
    ? <div className="flyout-panel">
        {children}
      </div>
    : null;
  }

export default SideBarFlyoutWithInputs;
