import { FormikValues } from "formik";
import {
  DepreciationScheduleType,
  EUserRoles,
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  VEHICLEFILETOFIELD,
} from "../enums";
import { IBackendFormMapping } from './IBackendFormMapping';
import { OptionsData } from '@avalara/skylab-sdk';

export interface SelectOption extends Omit<OptionsData, "value"> {
  // eslint-disable-next-line
  item?: any
  name?: string
  value: number | string | boolean | null
}

export interface IBreadCrumb {
  value: string;
  href?: string;
}

export interface Accoridion {
  expanded?: boolean;
}

export interface Asset {
  id: string;
  status: string;
  customerName: string;
  legalEntityName: string;
  legalEntityNumber: string;
  state: string;
  propertyName: string;
  assetNumber: string;
  assetAcquisitionDate: string;
  costType: string;
  taxYear: string;
  assessor: string;
  assessorAccountNumber: string;
  assetClass: string;
  propertyId: string;
  propertyNumber: string;
}

export interface Analysis {
  name: string;
  value: string;
  source?: boolean;
  analysis?: boolean;
}

export interface ExtAsset extends Asset {
  checked: boolean;
}

export type IToastMessageType = 'info' | 'success' | 'warning' | 'error'
export interface IToastMessage {
  message: string;
  visible: boolean;
  type?: IToastMessageType;
}

export interface CurrentUserPlan {
  isFreeTrial: boolean;
  expiration: string;
  numberOfReturnsPurchased: number;
  numberOfReturnsFiled: number;
  numberOfAssetsCreated: number;
  numberOfAssetsAllowed: number;
}

export interface ByState {
  stateId: number;
  numberOfReturnsToFile: number;
}

export interface Deadlines {
  stateId: number;
  deadline: string;
  numberOfReturnsToFile: number;
  byStates: ByState[];
}

export interface TaxReturnProcess {
  companies: boolean;
  legalEntities: boolean;
  properties: boolean;
  assets: boolean;
  returns: boolean;
  mapAssetClasses: boolean;
  previewReturns: boolean;
  approveReturns: boolean;
  generatePackages: boolean;
  fileReturns: boolean;
}

export interface TaxtInfor {
  currentUserPlan: CurrentUserPlan;
  taxYear: number;
  deadlines: Deadlines[];
  taxReturnProcess: TaxReturnProcess;
}

export interface State {
  id: number;
  name: string;
  abbreviation: string;
}

export interface ICompany extends Accoridion {
  name: string;
  number: string;
  naicsCode: number | null;
  mailingAddress: {
    line1: string;
    line2: string;
    city: string;
    stateId: number | null;
    postalCode: string;
  };
  packageReturnSameAsMailingAddress: boolean;
  packageReturnAddress: {
    line1: string;
    line2: string;
    city: string;
    stateId: number | null;
    postalCode: string;
  };
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactTitle: string;
  primaryContactEmailAddress: string;
  primaryContactPhoneNumber: string;
  federalTaxId: string;
  incorporationType: string;
  incorporationStateId: number | null;
  incorporateDate: string;
  fiscalYearStartDate: string;
  active: boolean;
}

export interface ILegalEntity extends Accoridion {
  id?: number;
  companyId: number | null;
  name: string;
  number: string;
  naicsCode: number | null;
  sameAsCompany: boolean;
  mailingAddress: {
    line1: string;
    line2: string;
    city: string;
    stateId: number | null;
    postalCode: string;
  };
  packageReturnSameAsMailingAddress: boolean;
  packageReturnAddress: {
    line1: string;
    line2: string;
    city: string;
    stateId: number | null;
    postalCode: string;
  };
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactTitle: string;
  primaryContactEmailAddress: string;
  primaryContactPhoneNumber: string;
  federalTaxId: string;
  incorporationType: string;
  incorporationStateId: number | null;
  incorporateDate: string;
  fiscalYearStartDate: string;
  active: boolean;
}

export interface IBackendFormDefault {
  id?: number;
  createdByUserId?: number;
  updatedByUserId?: null;
  assessorTaxYearId?: number;
  formId: number | null;
  form?: {
    id: number;
    name: string;
  };
  isDefaultForm: boolean;
  defaultDepreciationScheduleCollectionId: number | null;
  defaultDepreciationScheduleCollection?: {
    name: string
  } | null;
  defaultIndexTableCollectionId: number | null;
  defaultIndexTableCollection?: {
    name: string
  } | null;
  mappings?: IBackendFormMapping[];
}

export interface TaxYear {
  id: number;
  taxYear: string | number;
  mailingTitle: string;
  mailingName: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
  extensionsOffered: boolean;
  extensionsOriginalFormRequired: boolean;
  extensionsCopyOfFormRequired: boolean;
  returnsDeadline: string;
  assessmentDateMonth: number;
  assessmentDateDay: number;
  assessmentRatio: number;
  returnsOriginalFormRequired: boolean;
  returnsCopyOfFormRequired: boolean;
  returnsWetSignatureRequired: boolean;
  returnsLicensedRequired: boolean;
  returnsNotarizationRequired: boolean;
  returnsDigitalNotarizationAccepted: boolean;
  returnsDeadlineIfExtended: string;
  website?: string;
  inventoryOriginalCostRule?: string | null;
  extensionsDeadline?: string | null;
  acquisitionYearType?: string | null;
  formDefaults?: IBackendFormDefault[];
}

export interface AssessorAccount extends Accoridion {
  active: boolean;
  alternateReference: unknown;
  assessor: Assessor;
  assessorId: number;
  costCenter: string;
  costCenterInheritFromProperty: boolean;
  createdByUserId: null;
  glAccountNumber: string;
  glAccountNumberInheritFromProperty: boolean;
  id: number;
  legalDescription: string;
  name: string;
  number: string;
  property: Property;
  propertyId: number;
  renditionNotes: string;
  updatedByUserId: number;
  userAccountId: number;
  showOptional?: boolean;
  taxYear: number;
  taxYears: unknown[];
  mailingAddresses: unknown[];
  selectedTaxYearId: number;
}

export interface Assessor {
  id: number;
  createdByUserId: number;
  updatedByUserId: number;
  name: string;
  stateId: number;
  taxYears: TaxYear[];
  mailingTitle?: string;
  mailingName?: string;
  mailingAddresses?: IBackendAssessorAddress[];
}

export interface IBackendTaxYearUpdate {
  id?: number;
  taxYear?: number | null;
  mailingTitle?: string;
  mailingName?: string;
  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZipCode?: string;
  extensionsOffered?: boolean;
  extensionsOriginalFormRequired?: boolean;
  extensionsCopyOfFormRequired?: boolean;
  returnsDeadline?: string | null;
  extensionsDeadline?: string | null;
  assessmentDateMonth?: number | null;
  assessmentDateDay?: number | null;
  assessmentRatio?: number | null;
  returnsOriginalFormRequired?: boolean;
  returnsCopyOfFormRequired?: boolean;
  returnsWetSignatureRequired?: boolean;
  returnsLicensedRequired?: boolean;
  returnsNotarizationRequired?: boolean;
  returnsDigitalNotarizationAccepted?: boolean;
  returnsDeadlineIfExtended?: string | null;
  inventoryOriginalCostRule?: string | null;
  website?: string;
  acquisitionYearType?: string | null;
  formDefaults?: IBackendFormDefault[];
}
export interface IBackendAssessorUpdate {
  name?: string;
  stateId?: number | null;
  taxYears?: IBackendTaxYearUpdate[];
  mailingTitle?: string;
  mailingName?: string;
  mailingAddresses?: IBackendAssessorAddress[];
}

export interface IBackendAssessorAddress {
  id?: number;
  createdByUserId?: number;
  updatedByUserId?: number;
  addressType: string;
  address: IBackendAddress;
}

export interface IBackendAddress {
  id?: number;
  createdByUserId?: number;
  updatedByUserId?: number;
  line1: string;
  line2: string;
  city: string;
  stateId: number | null;
  postalCode: string;
}

export interface IBackendDepreciationScheduleCollectionUpdate {
  name?: string;
  stateId?: number | null;
  taxYear?: number | null;
  type?: string;
}

export interface IBackendIndexTableCollectionUpdate {
  name?: string;
  stateId?: number | null;
  taxYear?: number | null;
}

export interface IBackendDepreciationScheduleUpdate {
  depreciationScheduleCollectionId: number;
  name?: string;
  floorFactor?: number;
}

export interface IBackendIndexTableUpdate {
  indexTableCollectionId: number;
  name?: string;
  floorFactor?: number;
}

export interface IBackendDepreciationFactorUpdate {
  depreciationScheduleId: number;
  factor: number;
}

export interface Property extends Accoridion {
  id: number | null;
  company?: ICompany;
  legalEntity?: ILegalEntity;
  companyId: number | null;
  legalEntities: ILegalEntity[];
  legalEntityId: number | null;
  number: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    stateId: number | null;
    state?: State;
    stateName?: string;
    postalCode: string;
  };
  name: string;
  doingBusinessAs: string;
  costCenter: string;
  openDate: string;
  closedDate: string;
  glAccountNumber: string;
  description: string;
  active: boolean;
  assessors: SelectOption[];
  assessorAccounts: AssessorAccount[];
  showOptional: boolean;
}

export interface Company extends ICompany {
  id: number;
  userId: number;
}

export interface MappedFile {
  "Company Name": string;
  "Company Number": string;
  "Legal Entity Name": string;
  "Legal Entity Number": string;
  "Property Number": string;
  State: string;
  "Account Number": string;
  Assessor: string;
  "Property Name": string;
  "Doing Business As": string;
  "Property Cost Center": string;
  "Open Date": string;
  "Closed Date": string;
  "Property GL Account": string;
  "Property Description": string;
  "Address 1": string;
  "Address 2": string;
  City: string;
  "Zip Code": string;
  "Account Name": string;
  "Alternate Reference": string;
  "GL Account Number": string;
  "Account Cost Center": string;
  "Legal Description": string;
  "Rendition Notes": string;
}

export interface Action {
  type: string;
  uploadFile: File;
  states: State[];
  maintenanceNotifications: MaintenanceNotification[];
  uploadAssetsFile: File;
  properties: FormikValues;
}

export interface ReduxState {
  uploadFile: File;
  states: State[];
  maintenanceNotifications: MaintenanceNotificationResponse;
  uploadAssetsFile: File;
  properties: FormikValues;
  dashboardInfo: TaxtInfor;
}

export interface ExtProperty extends Property {
  companyName: string;
  companyNumber: string;
  legalEntityName: string;
  legalEntityNumber: string;
  state: string;
  stateId: number | null;
  accountNumber: string;
  assessor: string;
  assessorId: number | null;
  id: number | null;
  checked: boolean;
  status: string;
  errors?: string[];
}

export interface ValidateResult {
  status: string;
  errors?: {
    message: string;
  }[];
}

export type Order = "asc" | "desc" | false;

export interface IErrorBase {
  errors: string[];
  message: string;
  userAccount: {
    setupCompleted: boolean;
  };
}

export interface Limit {
  "Company Name": number;
  "Company Number": number;
  "Legal Entity Name": number;
  "Legal Entity Number": number;
  "Property Number": number;
  State: number;
  "Account Number": number;
  Assessor: number;
  "Property Name": number;
  "Doing Business As": number;
  "Property Cost Center": number;
  "Open Date": number;
  "Closed Date": number;
  "Property GL Account": number;
  "Property Description": number;
  "Address 1": number;
  "Address 2": number;
  City: number;
  "Zip Code": number;
  "Account Name": number;
  "Alternate Reference"?: number;
  "GL Account Number": number;
  "Account Cost Center": number;
  "Legal Description": number;
  "Rendition Notes": number;
  "Asset Number": number;
  "Asset Acquisition Date": number;
  "Asset Class": number;
  "Original Cost": number;
  "Tax Year": number;
  "Assessor Account Number": number;
  "Asset Description": number;
  "Alternate Asset ID": number;
  "Asset Description 2": number;
  "Asset GL Code / Alternate Account Number": number;
  "Asset Life": number;
  "Asset Location": number;
  "Asset Rebook Cost": number;
  "Asset Rebook Date": number;
  "Company Cost Center": number;
  "Historical Asset Number": number;
  "LTD Depreciation": number;
  Notes: number;
  Obsolescence: number;
  "Original Assessor": number;
  "Original Assessor Account Number": number;
  "Rendered Market Value": number;
  "Asset origin": number;
  "Assessment Date": number;
  "Acquisition Date": number;
  "Vehicle Make": number;
  "Vehicle Model": number;
  "Vehicle VIN": number;
  "Vehicle Year": number;
  "Lessor Address": number;
  "Lessor Name": number;
}

export interface ModalAction {
  type: string;
  href?: string;
}

export interface ModalState {
  isOpen: boolean;
  href: string | undefined;
}

export interface FixedAssetFile {
  "Company Name": string;
  "Legal Entity Name": string;
  State: string;
  "Property Number": string;
  "Asset Number": string;
  "Asset Acquisition Date": string;
  "Asset Class": string;
  "Original Cost": string;
  "Tax Year": string;
  Assessor: string;
  "Assessor Account Number": string;
  "Asset Description": string;
  "Property Name": string;
  "Alternate Asset ID": number;
  "Asset Description 2": string;
  "Asset GL Code / Alternate Account Number": string;
  "Asset Life": string;
  "Asset Location": string;
  "Asset Rebook Cost": string;
  "Asset Rebook Date": string;
  "Company Cost Center": string;
  "Company Number": string;
  "Historical Asset Number": string;
  "LTD Depreciation": string;
  Notes: string;
  Obsolescence: string;
  "Original Assessor": string;
  "Original Assessor Account Number": string;
  "Legal Entity Number": string;
  "Rendered Market Value": string;
}

export interface InventoryFile {
  "Company Name": string;
  "Legal Entity Name": string;
  "Property Number": string;
  State: string;
  "Asset Number": string;
  "Assessment Date": string;
  "Asset Class": string;
  "Tax Year": string;
  Assessor: string;
  "Assessor Account Number": string;
  "Asset Description": string;
  "Property Name": string;
  January: string;
  February: string;
  March: string;
  Apri: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  "Alternate Asset ID": number;
  Notes: string;
  "Legal Entity Number": string;
}

export interface VehicleFile {
  "Company Name": string;
  "Legal Entity Name": string;
  State: string;
  "Property Number": string;
  "Asset Number": string;
  "Asset Acquisition Date": string;
  "Asset Class": string;
  "Original Cost": string;
  "Tax Year": string;
  Assessor: string;
  "Assessor Account Number": string;
  "Asset Description": string;
  "Property Name": string;
  "Vehicle Make": string;
  "Vehicle Model": string;
  "Vehicle VIN": string;
  "Vehicle Year": string;
  "Alternate Asset ID": number;
  "Asset GL Code / Alternate Account Number": string;
  "Asset Life": string;
  "Company Cost Center": string;
  "Historical Asset Number": string;
  "LTD Depreciation": string;
  Notes: string;
  "Legal Entity Number": string;
  "Rebook Cost": string;
  "Rebook Date": string;
  "Rendered Market Value": string;
}

export interface LeasedVehicleFile {
  "Company Name": string;
  "Legal Entity Name": string;
  State: string;
  "Property Number": string;
  "Asset Number": string;
  "Asset Acquisition Date": string;
  "Asset Class": string;
  "Tax Year": string;
  Assessor: string;
  "Assessor Account Number": string;
  "Asset Description": string;
  "Lease End Date": string;
  "Lease Start Date": string;
  "Lessor Address": string;
  "Lessor Name": string;
  "Original Cost": string;
  "Property Name": string;
  "Vehicle Make": string;
  "Vehicle Model": string;
  "Vehicle VIN": string;
  "Vehicle Year": string;
  "Alternate Asset ID": number;
  "Asset GL Code / Alternate Account Number": string;
  "Asset Life": string;
  "Company Cost Center": string;
  "LTD Depreciation": string;
  "Monthly Rent": string;
  Notes: string;
  "Legal Entity Number": string;
  "Rebook Cost": string;
  "Rebook Date": string;
  "Rendered Market Value": string;
}

export interface LeasedEquipmentFile {
  "Company Name": string;
  "Legal Entity Name": string;
  State: string;
  "Property Number": string;
  "Asset Number": string;
  "Acquisition Date": string;
  "Asset Class": string;
  "Tax Year": string;
  Assessor: string;
  "Assessor Account Number": string;
  "Asset Description": string;
  "Lease End Date": string;
  "Lease Start Date": string;
  "Lessor Address": string;
  "Lessor Name": string;
  "Original Cost": string;
  "Property Name": string;
  "Alternate Asset ID": number;
  "Asset GL Code / Alternate Account Number": string;
  "Asset Life": string;
  "Company Cost Center": string;
  "LTD Depreciation": string;
  "Monthly Rent": string;
  Notes: string;
  "Legal Entity Number": string;
  "Rebook Cost": string;
  "Rebook Date": string;
  "Rendered Market Value": string;
}

export type OriginalFileField =
  | MappedFile
  | FixedAssetFile
  | InventoryFile
  | VehicleFile
  | LeasedVehicleFile
  | LeasedEquipmentFile;

export interface AssetField {
  id: number;
  checked: boolean;
  status: 'In progress' | 'Approved' | 'Filed';
  rolloverStatus: 'Ready' | 'Missing Assessor Account';
  companyId: number | null;
  assessorAccountId: number | null;
  number: string;
  class: string;
  acquisitionDate: string;
  taxYear: number;
  alternateAssetId: string;
  description: string;
  notes: string;
  disposed: boolean;
  isAddition: boolean;
  type: string;
  fixedAsset: FixedAsset | null;
  inventory: Inventory | null;
  vehicle: Vehicle | null;
  leasedVehicle: LeasedVehicle | null;
  leasedEquipment: LeasedEquipment | null;
  origin?: string | null;
  errors?: string[];
}

export interface FixedAsset {
  description2: string;
  glCodeAlternateAccountNumber: string;
  life: string;
  location: string;
  companyCostCenter: string;
  rebookDate: string;
  rebookCost: number;
  historicalNumber: string;
  ltdDepreciation: number;
  obsolescence: number;
  originalAssessorName: string;
  originalAssessorAccountNumber: string;
  originalCost: number;
  renderedMarketValue: number;
}

export interface Inventory {
  januaryAmount: number;
  februaryAmount: number;
  marchAmount: number;
  aprilAmount: number;
  mayAmount: number;
  juneAmount: number;
  julyAmount: number;
  augustAmount: number;
  septemberAmount: number;
  octoberAmount: number;
  novemberAmount: number;
  decemberAmount: number;
}

export interface Vehicle {
  glCodeAlternateAccountNumber: string;
  life: string;
  companyCostCenter: string;
  rebookDate: string;
  rebookCost: number;
  historicalNumber: string;
  ltdDepreciation: number;
  originalCost: number;
  renderedMarketValue: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string;
  vehicleYear: string;
}

export interface LeasedVehicle {
  glCodeAlternateAccountNumber: string;
  life: string;
  companyCostCenter: string;
  rebookDate: string;
  rebookCost: number;
  leasedStartDate: string;
  leasedEndDate: string;
  lessorAddress: string;
  lessorName: string;
  ltdDepreciation: number;
  monthlyRent: number;
  originalCost: number;
  renderedMarketValue: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string;
  vehicleYear: number;
}

export interface LeasedEquipment {
  glCodeAlternateAccountNumber: string;
  life: string;
  companyCostCenter: string;
  rebookDate: string;
  rebookCost: number;
  leasedStartDate: string;
  leasedEndDate: string;
  lessorAddress: string;
  lessorName: string;
  ltdDepreciation: number;
  monthlyRent: number;
  originalCost: number;
  renderedMarketValue: number;
}

export interface TooltipItem {
  section: string;
  keyword: string;
  description: string;
  enabled: boolean;
}

export interface PageTooltip {
  id: number;
  page: string;
  items: TooltipItem[];
}

export interface PurchaseReturnResponse {
  items: PurchaseReturn[];
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export interface PurchaseReturn {
  id?: number;
  firstName: string;
  lastName: string;
  businessName: string;
  email: string;
  freeTrialStartDate?: string;
  freeTrialEndDate?: string;
  userPurchases: PurchasedReturnYear[];
  setupCompleted?: boolean;
}

export interface PurchasedReturnYear {
  id?: number;
  taxYear: number;
  returnsPurchased: number;
}

export interface HelpCategoryResponse {
  items: HelpCategory[];
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export interface HelpCategory {
  displayOrder: number;
  id?: number;
  title: string;
  topics: HelpCategoryTopic[];
}

export interface HelpCategoryTopic {
  displayOrder: number;
  helpCategoryId?: number;
  id?: number;
  versions: SubmitTopicVersion[];
}

export enum TopicVersionStatus {
  Published = "Published",
  Draft = "Draft",
}

export interface TopicVersion {
  helpTopicId?: number;
  hiddenFromPublic: boolean;
  id?: number;
  shortContent?: string;
  status: string;
  tags: string[];
  title: string;
}

export interface SubmitTopicVersion extends TopicVersion {
  content?: string;
  s3PresignedUrl?: string;
}

export interface OriginColumns {
  propertyData: string;
  mappingPriority: string;
  expectData: string;
}

export type TypeColumnsKeys =
  | typeof FIXEDASSETFILETOFIELD
  | typeof INVENTORYFILETOFIELD
  | typeof VEHICLEFILETOFIELD
  | typeof LEASEDVEHICLEFILETOFIELD
  | typeof LEASEDEQUIPMENTFILETOFIELD;

export interface UploadFile {
  id: number;
  assetType: string;
  status: string;
  document: {
    name: string;
    lengthInBytes: number;
  };
  createdAt: string;
  step: string;
}

export interface AssetClass {
  id: number;
  createdByUserId: number | null;
  name: string;
  updatedByUserId: number | null;
  description: string;
}

export interface GlobalAssetMapping {
  id: number;
  commonClassId: number | null;
  createdByUserId: number | null;
  name: string;
  updatedByUserId: number | null;
  userAccountId: number;
}

export interface FormDetails {
  id: number;
  status: string;
  state: string;
  taxYear: number;
  formName: string;
  numberOfAccounts: number;
}

export interface ExtFormDetails extends FormDetails {
  checked: boolean;
}

export interface PackageContent {
  id: number;
  status: string;
  state: string;
  taxYear: number;
  formName: string;
  numberOfAccounts: number;
}

export interface ExtPackageContent extends PackageContent {
  checked: boolean;
}

export interface PackageContentForm {
  id: number;
  name: string;
  previewImgUrl: string;
  locked: boolean;
}

export interface ExtPackageContentForm extends PackageContentForm {
  checked: boolean;
}

export interface ReturnsItem {
  assessorAccountData: AssessorAccount;
  assessorAccount: AssessorAccount;
  assessorAccountId: number;
  createdByUserId: number;
  depreciationScheduleCollectionId: number;
  formId: number;
  id: number;
  indexTableCollectionId: number;
  priorYearTotalAssessedValue: number;
  priorYearTotalReportedCost: number;
  status: string;
  taxYear: number;
  totalAssessedValue: number;
  totalReportedCost: number;
  updatedByUserId: number;
  userAccountId: number;
}

export interface AccountAsset {
  id: number;
  status: string;
  assetType: string;
  assetClass: string;
  assetNumber: string;
  assetDescription: string;
  originalCost: number;
  assessedValue: number;
}

export interface ExtAccountAsset extends AccountAsset {
  checked: boolean;
}

export interface UserModal {
  id: number;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
  roles: EUserRoles[];
  createdAt: string;
  userAccountId: number;
  isFreeTrial: boolean;
  userAccount?: PurchaseReturn;
  status?: string;
}

export interface ExtUserModal extends UserModal {
  checked: boolean;
}

export interface PurchaseModal {
  id: number;
  taxYear: string;
  returnsPurchased: number;
  purchaseDate: string;
  returnsUsed: number;
  createdAt: string;
  renewalDate: string;
}

export interface NextLocation {
  location: {
    pathname: string;
    search: string;
  };
}

export interface FormDetail {
  id: number;
  name: string;
  status: string;
  stateId: number;
  state: State;
  taxYear: number;
}

export interface CustomForm {
  id: number;
  formId: number;
  form: FormDetail;
  numberOfAccounts: number;
  status: string;
  taxYear: number;
  userAccountId: number;
}

export interface ExtCustomForm extends CustomForm {
  checked: boolean;
}

export interface FormQuestion {
  id: number;
  formId: number;
  text: string;
  type: string;
  required: boolean;
  options?: string[];
  pageNumber?: number;
  section?: string;
}

export interface CustomerFormAnswer {
  id: number;
  formQuestionId: number;
  customerFormId: number;
  userAccountId: number;
  answerCompleted: boolean;
  answers: [] | null;
  returnId: number;
}

export interface FormAttachment {
  createdByUserId: number | null;
  formId: number;
  id: number;
  isReturnForm: boolean;
  name: string;
  required: boolean;
  templateDocument: {
    createdByUserId: number | null;
    id: number;
    lengthInBytes: number;
    mimeType: string;
    name: string;
    type: string;
  };
  templateDocumentId: number;
  thumbnailDocumentId: number;
  updatedByUserId: number | null;
}

export interface CustomerFormAttachment {
  createdByUserId: number | null;
  customerFormId: number;
  formAttachment: FormAttachment;
  formAttachmentId: number;
  id: number;
  returnId: number | null;
  updatedByUserId: number | null;
  userAccountId: number;
}

export interface ManageFormDetailData {
  id: number;
  formId: number;
  form: FormDetail | null;
  customerFormAnswers: CustomerFormAnswer[];
  customerFormAttachments: CustomerFormAttachment[];
  numberOfAccounts: number;
  status: string;
  taxYear: number;
  userAccountId: number;
}

export interface ReturnAssetMapping {
  id: number;
  assessedValue: string;
  assessorId: number;
  asset: AssetField;
  assetId: number;
  commonClass?: {
    id: number;
    name: string;
  };
  commonClassId: number;
  createdByUserId: number;
  depreciationSchedule: DepreciationSchedule | null;
  depreciationScheduleCollectionId: number;
  depreciationScheduleId: number | null;
  formGroup?: {
    id: number;
    name: string;
    internalName: string;
  };
  formGroupId: number;
  formId: number | null;
  indexTable?: {
    id: number;
    name: string;
  };
  indexTableCollectionId: number | null;
  indexTableId: number | null;
  originalCost: string;
  reportable: boolean | null;
  stateId: number;
  status: string;
  taxable: boolean;
  vintage: number;
  isAddition: boolean;
  disposed: boolean;
}

export interface ReturnAssetMappingResponse {
  items: ReturnAssetMapping[];
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export interface ReportItem {
  reportName: string;
  createdAt: string;
  id: number;
  reportId: string;
}

export interface ReturnForm {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  stateId: number;
  taxYear: number;
  name: string;
}

export interface IndexTableCollection {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  stateId: number;
  name: string;
  taxYear: number | null;
}

export interface DepreciationScheduleCollection {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  stateId: number;
  name: string;
  type: DepreciationScheduleType;
  taxYear: number | null;
}

export interface AvalaraStandardAssetClass {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  name: string;
  description: string;
}

export interface FormGroup {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  name: string;
  internalName: string;
  formId: number;
}

export interface DepreciationSchedule {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  depreciationScheduleCollectionId: number;
  name: string;
  factors: DepreciationScheduleFactor[];
}

export interface DepreciationScheduleFactor {
  id: number;
  depreciationScheduleId: number;
  age: number;
  value: number;
}

export interface IndexTableFactor {
  id: number;
  indexTableId: number;
  age: number;
  value: number;
}

export interface IndexTable {
  id: number;
  createdByUserId: number | null;
  updatedByUserId: number | null;
  indexTableCollectionId: number;
  name: string;
  factors: IndexTableFactor[];
}

export type BaseRoutes = 'dashboard' | 'properties' | 'assets' | 'users' | 'plans' | 'taxyearselection' | 'forms' | 'returns' | 'reports' | 'admin' | 'help';

export interface Tab {
  name: string;
  disabled?: boolean;
}

export interface PurchaseReturnUI extends PurchaseReturn {
  roles: undefined | string[];
}

export interface SystemTaxYear {
  taxYear: number;
  isActive: boolean;
  isCurrent: boolean;
}

export interface MaintenanceNotificationResponse {
  items: MaintenanceNotification[];
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export interface MaintenanceNotification {
  id?: number;
  content: string;
  shortContent?: string;
  startDate?: string;
  endDate?: string;
  isActive?: boolean;
}