import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../../../components/FieldInput/FieldInput';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import FieldDate from '../../../components/FieldDate/FieldDate';
import FieldToggleCheckbox from '../../../components/FieldToggleCheckbox/FieldToggleCheckbox';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import validateEmpty from '../../../utils/validateEmpty';
import {addCompany, getCompany, getErrorMessage, updateCompany} from '../../../services';
import {
  IErrorBase,
  IToastMessage,
  ReduxState,
  SelectOption,
  State,
} from '../../../interface';
import style from './CompanySetup.module.scss';
import moment from 'moment';
import {AxiosResponse} from 'axios';
import {
  INCORPORATIONTYPE,
  PICKCOMPANYFIELD,
  SEARCHABLELIMIT,
} from '../../../constants';
import {connect} from 'react-redux';
import {
  getFirstDateOfThisYear,
  getLastDateOfThisYear,
} from '../../../utils/date';
import {ReactComponent as SearchIcon} from './search.svg';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import validateEmail from '../../../utils/validateEmail';
import { useAppDispatch } from '../../../redux/store';
import { getDashboardInfo } from '../../../redux/features/dashboard-info-slice';

// Init formik values
const newInit = {
  expanded: true,
  name: '',
  number: '',
  naicsCode: null,
  mailingAddress: {
    line1: '',
    line2: '',
    city: '',
    stateId: null,
    postalCode: '',
  },
  packageReturnSameAsMailingAddress: false,
  packageReturnAddress: {
    line1: '',
    line2: '',
    city: '',
    stateId: null,
    postalCode: '',
  },
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactTitle: '',
  primaryContactEmailAddress: '',
  primaryContactPhoneNumber: '',
  federalTaxId: '',
  incorporationType: '',
  incorporationStateId: null,
  incorporateDate: '',
  fiscalYearStartDate: '',
  active: true,
};

// Define the required fields
const requiredField = [
  'name',
  'number',
  'mailingAddress.line1',
  'mailingAddress.city',
  'mailingAddress.stateId',
  'mailingAddress.postalCode',
  'packageReturnAddress.line1',
  'packageReturnAddress.city',
  'packageReturnAddress.stateId',
  'packageReturnAddress.postalCode',
  'primaryContactFirstName',
  'primaryContactLastName',
  'primaryContactTitle',
];

interface Props {
  states: State[];
}

function CompanySetup(props: Props) {
  const dispatch = useAppDispatch();
  const {states} = props;
  const navigate = useNavigate();
  // Breadcrumb, dashboard depend on user skip or not
  const {id} = useParams();
  const [navBlock, setNavBlock] = useState<boolean>();
  const location = useLocation();

  const breadcrumb = [
    {
      value: 'Properties',
      href: '/properties',
    },
    {value: id ? 'Edit Company' : 'Company Setup'},
  ];
  const [statincorporation, setstatincorporation] = useState<SelectOption[]>(
    [],
  );
  const [fetchingCompany, setFetchingCompany] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Get options of select from JSON
  const NAVIGATE =
    (id && `/properties`) || (Boolean(location.state) && `/dashboard`) || `/properties`;

  useEffect(() => {
    setstatincorporation(
      states.map((o: State) => ({
        value: o['id'],
        label: o['abbreviation'],
        name: o['name'],
      })),
    );
  }, [states]);

  function handleResponse(promise: Promise<AxiosResponse>) {
    return promise
      .then((e: AxiosResponse<IErrorBase>) => {
        if (_.inRange(e.status, 200, 299)) {
          dispatch(getDashboardInfo());
          navigate(NAVIGATE);
        } else {
          setToastMessage({
            visible: true,
            message: `Error occurs, please try again later.`,
            type: "error",
          });
        }
      })
      .catch((e) => {
        setToastMessage({
          visible: true,
          message: getErrorMessage(e),
          type: "error",
        });
      })
      .finally(() => setSubmitting(false));
  }

  // Flag for submit or not
  const [issubmit, setissubmit] = useState<boolean>(false);
  const [submitToast, setSubmitToast] = useState<boolean>(false);
  const [initialValues, setinitialValues] = useState(newInit);
  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => {
      // Validate form,if Same As Mailing Address checkbox is checked,not validate the package fields
      if (issubmit) {
        let _required = _.cloneDeep(requiredField);
        if (values.packageReturnSameAsMailingAddress) {
          _required = _.without(
            _required,
            'packageReturnAddress.line1',
            'packageReturnAddress.city',
            'packageReturnAddress.stateId',
            'packageReturnAddress.postalCode',
          );
        }
        const errors = _.defaults(
          validateEmpty(_.pick(values, _required)),
          values.primaryContactEmailAddress &&
            validateEmail({
              primaryContactEmailAddress: values.primaryContactEmailAddress,
            }),
        );
        const result = _.every(_.values(errors), (e: string | []) => {
          if (_.isObject(e)) {
            return _.every(_.values(e), (se) => {
              return _.every(se, _.isEmpty);
            });
          } else {
            return _.every(e, _.isEmpty);
          }
        });

        if (!result) {
          if (submitToast) {
            setSubmitToast(false);
            setToastMessage({
              visible: true,
              message:
                'The Company you save had some errors that need to be fixed. Please address these to move forward.',
              type: 'error',
            });
          }
          return errors;
        }
      }
    },
    onSubmit: () => {
      setNavBlock(false);
      const values = formik.values.packageReturnSameAsMailingAddress
        ? _.omit(
            {
              ...formik.values,
              fiscalYearStartDate: formik.values.fiscalYearStartDate
                ? moment(formik.values.fiscalYearStartDate).format('MM/DD')
                : '',
              naicsCode: !formik.values.naicsCode
                ? null
                : _.toNumber(formik.values.naicsCode),
            },
            'packageReturnAddress',
          )
        : {
            ...formik.values,
            fiscalYearStartDate: formik.values.fiscalYearStartDate
              ? moment(formik.values.fiscalYearStartDate).format('MM/DD')
              : '',
            naicsCode: !formik.values.naicsCode
              ? null
              : _.toNumber(formik.values.naicsCode),
          };

      _.forIn(values, (v, k) => {
        if (_.isObject(v)) {
          _.unset(values, k);
          _.set(values, k, _.pickBy(v, _.identity));
        }
      });
      const result = _.pick(
        _.pickBy(values, (v) => (!_.isBoolean(v) ? _.identity(v) : true)),
        PICKCOMPANYFIELD,
      );
      if (id) {
        setSubmitting(true);
        handleResponse(updateCompany(id, result))
      } else {
        setSubmitting(true);
        handleResponse(
          addCompany({
            items: [result],
          })
        );
      }
    },
  });

  useEffect(() => {
    if (id) {
      setFetchingCompany(true);
      getCompany(id)
        .then((d) => {
          setinitialValues(d.data);
          setFetchingCompany(false);
        })
        .catch(() => {
          setFetchingCompany(false);
        });
    }
  }, [id]);

  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {/* Error message box,if there are errors message */}
        {toastMessage?.visible && (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className='validate-error'
              visiableHandler={(value) =>
                setToastMessage({...toastMessage, visible: value})
              }
            />
          </div>
        )}
        <h2>
          {id ? (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Edit company'
            >
              <span>Edit company</span>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Add company'
            >
              <span>Add company</span>
            </TooltipWrapper>
          )}
        </h2>
        <form className={style['main']} onSubmit={formik.handleSubmit}>
          {/* Company form list */}
          <div className={classNames(style['section'])}>
            {/* Form header,remove icon only appear when form count > 1 */}
            <div className={style['header']}>
              <h3>
                {formik.values.name ? formik.values.name : `Untitled Company 1`}
              </h3>
              <div className={style['operate']}>
                <FieldToggleCheckbox
                  label='Active'
                  value={formik.values.active}
                  onChange={(value) => formik.setFieldValue(`active`, value)}
                />
                <i
                  className={style['edit']}
                  role='button'
                  onClick={() => formik.setFieldValue(`expanded`, true)}
                ></i>
              </div>
            </div>
            <div className={style['form']}>
              {/* Company Info Fieldset */}
              <fieldset>
                <legend>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Company Info'
                  >
                    <span>Company Info</span>
                  </TooltipWrapper>
                </legend>
                <div className={style['row']}>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Company Name'
                      name='name'
                      value={formik.values.name}
                      onChange={(value) => formik.setFieldValue(`name`, value)}
                      maxLength={75}
                      error={
                        formik.errors && (formik.errors as FormikValues).name
                      }
                      required
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Company Number'
                      name='number'
                      value={formik.values.number}
                      onChange={(value) =>
                        formik.setFieldValue(`number`, value)
                      }
                      maxLength={50}
                      error={
                        formik.errors && (formik.errors as FormikValues).number
                      }
                      required
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='NAICS Code'
                      name='naicsCode'
                      placeholder='Start Typing…'
                      value={
                        _.isNull(formik.values.naicsCode)
                          ? ''
                          : formik.values.naicsCode
                      }
                      onChange={(value) =>
                        /^\d*$/.test(value) &&
                        formik.setFieldValue(`naicsCode`, value)
                      }
                      icon={<SearchIcon />}
                    />
                    <p className={style['note']}>
                      Don’t know your code?{' '}
                      <a
                        href={`https://www.census.gov/naics/?year=2022&input=${formik.values.naicsCode}&details=${formik.values.naicsCode}`}
                        className='primary-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Find yours here
                      </a>
                    </p>
                  </div>
                </div>
              </fieldset>
              {/* Mailing Address Fieldset */}
              <fieldset>
                <legend>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Mailing Address'
                  >
                    <span>Mailing Address</span>
                  </TooltipWrapper>
                </legend>
                <div className={style['row']}>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Address Line 1'
                      name='mailingAddress.line1'
                      value={formik.values.mailingAddress?.line1}
                      maxLength={100}
                      onChange={(value) =>
                        formik.setFieldValue(`mailingAddress.line1`, value)
                      }
                      error={
                        formik.errors &&
                        (formik.errors as FormikValues).mailingAddress?.line1
                      }
                      tooltipText='Mailling Address Line 1'
                      required
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Address Line 2'
                      name='mailingAddress.line2'
                      value={formik.values.mailingAddress?.line2}
                      maxLength={100}
                      onChange={(value) =>
                        formik.setFieldValue(`mailingAddress.line2`, value)
                      }
                      tooltipText='Mailling Address Line 2'
                    />
                  </div>
                  <div className={style['column']}>
                    <div className={style['address-row']}>
                      <div className={style['address-column']}>
                        <FieldInput
                          labelText='City'
                          name='mailingAddress.city'
                          value={formik.values.mailingAddress?.city}
                          maxLength={50}
                          onChange={(value) =>
                            formik.setFieldValue(`mailingAddress.city`, value)
                          }
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).mailingAddress?.city
                          }
                          tooltipText='Mailling Address City'
                          required
                        />
                      </div>
                      <div className={style['address-column']}>
                        <FieldSelect
                          labelText='State'
                          options={statincorporation}
                          selectId={formik.values.mailingAddress?.stateId}
                          noSearch={
                            _.gt(statincorporation.length, SEARCHABLELIMIT)
                              ? false
                              : true
                          }
                          onSelect={(value) =>
                            formik.setFieldValue(
                              `mailingAddress.stateId`,
                              value?.value ? value?.value : null,
                            )
                          }
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).mailingAddress
                              ?.stateId
                          }
                          tooltipText='Mailling Address State'
                          required
                        />
                      </div>
                      <div className={style['address-column']}>
                        <FieldInput
                          labelText='Postal Code'
                          name='mailingAddress.postalCode'
                          value={formik.values.mailingAddress?.postalCode}
                          maxLength={10}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `mailingAddress.postalCode`,
                              value,
                            )
                          }
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).mailingAddress
                              ?.postalCode
                          }
                          tooltipText='Mailling Address Postal Code'
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              {/* Package Return Address Fieldset,it will hide when Same Mailing Address chexkbox is checked */}
              <fieldset className={style['checkbox-fieldset']}>
                <legend>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Package Return Address'
                  >
                    <span>Package Return Address</span>
                  </TooltipWrapper>
                </legend>
                <div className={style['custom-checkbox']}>
                  <CustomCheckbox
                    label='Same as Mailing Address'
                    value={formik.values.packageReturnSameAsMailingAddress}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `packageReturnSameAsMailingAddress`,
                        value,
                      )
                    }
                  />
                </div>
                {!formik.values.packageReturnSameAsMailingAddress && (
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Address Line 1'
                        name='packageReturnAddress.line1'
                        value={formik.values.packageReturnAddress?.line1}
                        maxLength={100}
                        onChange={(value) =>
                          formik.setFieldValue(
                            `packageReturnAddress.line1`,
                            value,
                          )
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).packageReturnAddress
                            ?.line1
                        }
                        tooltipText='Package Return Address Line 1'
                        required
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Address Line 2'
                        name='packageReturnAddress.line2'
                        value={formik.values.packageReturnAddress?.line2}
                        maxLength={100}
                        onChange={(value) =>
                          formik.setFieldValue(
                            `packageReturnAddress.line2`,
                            value,
                          )
                        }
                        tooltipText='Package Return Address Line 2'
                      />
                    </div>
                    <div className={style['column']}>
                      <div className={style['address-row']}>
                        <div className={style['address-column']}>
                          <FieldInput
                            labelText='City'
                            name='packageReturnAddress.city'
                            value={formik.values.packageReturnAddress?.city}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `packageReturnAddress.city`,
                                value,
                              )
                            }
                            maxLength={50}
                            error={
                              formik.errors &&
                              (formik.errors as FormikValues)
                                .packageReturnAddress?.city
                            }
                            tooltipText='Package Return Address City'
                            required
                          />
                        </div>
                        <div className={style['address-column']}>
                          <FieldSelect
                            labelText='State'
                            options={statincorporation}
                            selectId={
                              formik.values.packageReturnAddress?.stateId
                            }
                            noSearch={
                              _.gt(statincorporation.length, SEARCHABLELIMIT)
                                ? false
                                : true
                            }
                            onSelect={(value) =>
                              formik.setFieldValue(
                                `packageReturnAddress.stateId`,
                                value?.value ? value?.value : null,
                              )
                            }
                            error={
                              formik.errors &&
                              (formik.errors as FormikValues)
                                .packageReturnAddress?.stateId
                            }
                            tooltipText='Package Return Address State'
                            required
                          />
                        </div>
                        <div className={style['address-column']}>
                          <FieldInput
                            labelText='Postal Code'
                            name='packageReturnAddress.postalCode'
                            value={
                              formik.values.packageReturnAddress?.postalCode
                            }
                            onChange={(value) =>
                              formik.setFieldValue(
                                `packageReturnAddress.postalCode`,
                                value,
                              )
                            }
                            maxLength={10}
                            error={
                              formik.errors &&
                              (formik.errors as FormikValues)
                                .packageReturnAddress?.postalCode
                            }
                            tooltipText='Package Return Address Postal Code'
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </fieldset>
              {/* Primary Contact Fieldset */}
              <fieldset>
                <legend>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Primary Contact'
                  >
                    <span>Primary Contact</span>
                  </TooltipWrapper>
                </legend>
                <div className={style['row']}>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='First Name'
                      name='primaryContactFirstName'
                      value={formik.values.primaryContactFirstName}
                      onChange={(value) =>
                        formik.setFieldValue(`primaryContactFirstName`, value)
                      }
                      error={
                        formik.errors &&
                        (formik.errors as FormikValues).primaryContactFirstName
                      }
                      required
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Last Name'
                      name='primaryContactLastName'
                      value={formik.values.primaryContactLastName}
                      onChange={(value) =>
                        formik.setFieldValue(`primaryContactLastName`, value)
                      }
                      error={
                        formik.errors &&
                        (formik.errors as FormikValues).primaryContactLastName
                      }
                      required
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Title'
                      name='primaryContactTitle'
                      value={formik.values.primaryContactTitle}
                      onChange={(value) =>
                        formik.setFieldValue(`primaryContactTitle`, value)
                      }
                      error={
                        formik.errors &&
                        (formik.errors as FormikValues).primaryContactTitle
                      }
                      required
                    />
                  </div>
                </div>
                <div className={style['row']}>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Primary Contact Email Address'
                      name='primaryContactEmailAddress'
                      value={formik.values.primaryContactEmailAddress}
                      onChange={(value) =>
                        formik.setFieldValue(
                          `primaryContactEmailAddress`,
                          value,
                        )
                      }
                      error={
                        formik.errors &&
                        (formik.errors as FormikValues).primaryContactEmailAddress
                      }
                    />
                  </div>
                  <div className={style['column']}>
                    <FieldInput
                      labelText='Primary Contact Phone Number'
                      name='primaryContactPhoneNumber'
                      value={formik.values.primaryContactPhoneNumber}
                      onChange={(value) =>
                        formik.setFieldValue(`primaryContactPhoneNumber`, value)
                      }
                    />
                  </div>
                  <div className={style['column']}></div>
                </div>
              </fieldset>
              {/* Optional Information Fieldset */}
              {formik.values.showOptional && (
                <fieldset>
                  <legend>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Optional Information'
                    >
                      <span>Optional Information</span>
                    </TooltipWrapper>
                  </legend>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Federal Tax ID'
                        name='federalTaxId'
                        value={formik.values.federalTaxId}
                        onChange={(value) =>
                          formik.setFieldValue(`federalTaxId`, value)
                        }
                        tooltipText='Federal Tax ID'
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldSelect
                        labelText='Incorporation Type'
                        options={INCORPORATIONTYPE}
                        selectText={formik.values.incorporationType}
                        noSearch={
                          _.gt(INCORPORATIONTYPE.length, SEARCHABLELIMIT)
                            ? false
                            : true
                        }
                        onSelect={(value) =>
                          formik.setFieldValue(
                            `incorporationType`,
                            value?.label ? value?.label : '',
                          )
                        }
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldSelect
                        labelText='State of Incorporation'
                        options={statincorporation}
                        selectId={formik.values.incorporationStateId}
                        noSearch={
                          _.gt(statincorporation.length, SEARCHABLELIMIT)
                            ? false
                            : true
                        }
                        onSelect={(value) =>
                          formik.setFieldValue(
                            `incorporationStateId`,
                            value?.value ? _.toNumber(value?.value) : null,
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <div className={style['row-date']}>
                        <div className={style['column-date']}>
                          <FieldDate
                            labelText='Incorporation Date'
                            selectValue={formik.values.incorporateDate}
                            onSelect={(value) =>
                              formik.setFieldValue(`incorporateDate`, value)
                            }
                          />
                        </div>
                        <div className={style['column-date']}>
                          <FieldDate
                            labelText='Fiscal Year Start Date'
                            primaryFormat='MM/DD'
                            minDate={getFirstDateOfThisYear()}
                            maxDate={getLastDateOfThisYear()}
                            selectValue={formik.values.fiscalYearStartDate}
                            onSelect={(value) =>
                              formik.setFieldValue(`fiscalYearStartDate`, value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={style['column']}></div>
                    <div className={style['column']}></div>
                  </div>
                </fieldset>
              )}
              <div className={style['bottom-bar']}>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Optional Information fields'
                >
                  <i
                    role='button'
                    className={classNames('link', style['toggle-link'])}
                    onClick={() =>
                      formik.setFieldValue(
                        `showOptional`,
                        !formik.values.showOptional,
                      )
                    }
                  >
                    {`${
                      formik.values.showOptional ? 'Hide' : 'Show'
                    } Optional Information fields`}
                  </i>
                </TooltipWrapper>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Required field'
                >
                  <span className={style['required']}>* Required field</span>
                </TooltipWrapper>
              </div>
            </div>
          </div>
          <div className={style['footer']}>
            {/* Submit link,it change the flag of submit */}
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save and complete'
              >
                <button
                  type='submit'
                  className='primary'
                  onClick={() => {
                    setissubmit(true);
                    setSubmitToast(true);
                  }}
                  disabled={submitting}
                >
                  Save and complete
                  {submitting ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  className='button secondary'
                  type='button'
                  onClick={() => navigate(NAVIGATE)}
                  disabled={submitting}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </form>
      </div>
      <DiscardChangeConfirmModal
        initialData={initialValues}
        inputData={formik.values}
        navBlock={navBlock}
      />

      <Loader isOpen={fetchingCompany} />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(CompanySetup);
