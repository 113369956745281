import classNames from 'classnames';
import {
  compact,
  Dictionary,
  get,
  isNil,
  isUndefined,
  keyBy,
  keys,
  last,
  mapValues,
  some,
  transform,
  values,
} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import FieldSelect from '../../../../components/FieldSelect/FieldSelect';
import Loader, {LoaderIndicator} from '../../../../components/Loader/Loader';
import {InputType} from '../../../../components/Table/components/Editor/Editor';
import InitTable, {Query} from '../../../../components/Table/Table';
import ToastMessage from '../../../../components/ToastMessage/ToastMessage';
import TooltipWrapper from '../../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../../enums';
import useDashboardInfo from '../../../../hook/useDashboardInfo';
import {
  AssetClass,
  GlobalAssetMapping,
  IToastMessage,
  SelectOption,
} from '../../../../interface';
import {getDashboardInfo} from '../../../../redux/features/dashboard-info-slice';
import {useAppDispatch} from '../../../../redux/store';
import {
  getAllAssetClass,
  getCommonAssetClass,
  getErrorMessage,
  saveAssetClasses,
} from '../../../../services';
import style from './FormsGlobalAssetMapping.module.scss';
import _ from 'lodash';
import useSelectedSystemTaxYear from '../../../../hook/useSelectedSystemTaxYear';

const {Table, Column, TopBar} = InitTable<GlobalAssetMapping>();

type IdMapping = {[id: number]: number | null};

const StatusOptions = ['Mapped', 'Unmapped'];

function FormsGlobalAssetMapping() {
  const appDispatch = useAppDispatch();
  const {taxYear} = useDashboardInfo();
  const selectedSystemTaxYear = useSelectedSystemTaxYear();
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [standardisedClassOptions, setStandardisedClassOptions] = useState<
    SelectOption[]
  >([]);
  const [items, setItems] = useState<GlobalAssetMapping[]>([]);
  const [standardClasses, setStandarizedClasses] = useState({
    list: [] as AssetClass[],
    byId: {} as Dictionary<AssetClass>,
  });
  const lastQueryRef = useRef<Query>();

  const [selected, setSelected] = useState<GlobalAssetMapping[]>([]);

  const [selectedItemsAssetClassId, setSelectedItemsAssetClassId] = useState<
    number | null
  >(null);
  const [assetClassOptions, setAssetClassOptions] = useState<SelectOption[]>([]);

  //Pagination
  const [total, setTotal] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);

  const getData = useCallback((query?: Query) => {
    if (taxYear && !isUndefined(query)) {
      setFetchingData(true);
      getAllAssetClass(query ? {
        page: query.pagination?.page,
        perPage: query.pagination?.pageSize,
        sortBy: last(keys(query.order)),
        sortDirection: last(values(query.order)),
        text: query.search,
        status: query.filter?.status as string,
        assetClassIds: query.filter?.name as number[],
        commonClassIds: query.filter?.['commonClass.name'] as number[],
        taxYear: taxYear?.toString(),
      } : {
        page: 1,
        taxYear: taxYear?.toString(),
      })
        .then((res) => {
          setItems(res.data.items);
          setTotal(res.data.total);
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  }, [taxYear]);

  useEffect(() => {
    getData(lastQueryRef.current);
  }, [taxYear]);

  const [fetchingCommonAssetClass, setFetchingCommonAssetClass] =
    useState(false);
  useEffect(() => {
    setFetchingCommonAssetClass(true);
    getCommonAssetClass()
      .then((res) => {
        setStandarizedClasses({
          byId: keyBy(res.data, (x) => x.id),
          list: res.data,
        });

        setStandardisedClassOptions(
          res.data.map((x) => ({...x, id: x.id?.toString(), label: x.name, value: x.id})),
        );
        setFetchingCommonAssetClass(false);
      })
      .catch(() => {
        setFetchingCommonAssetClass(false);
      });

    getAllAssetClass({
      taxYear: selectedSystemTaxYear.taxYear,
    })
      .then(result => {
        setAssetClassOptions(result.data.items as SelectOption[])
      });
  }, []);

  const [savingAssetClasses, setSavingAssetClasses] = useState(false);

  const saveMapping = (mapping: IdMapping) => {
    const itemsByKey = keyBy(items, (x) => x.id);
    const updates = mapValues(mapping, (commonClassId, id) => ({
      ...itemsByKey[id],
      commonClassId,
    }));

    setSavingAssetClasses(true);
    saveAssetClasses(values(updates))
      .then(() => {
        setItems(items.map((x) => updates[x.id] ?? x));

        setToastMessage({
          message: 'Asset class mappings are saved successfully.',
          visible: true,
          type: 'success',
        });
        appDispatch(getDashboardInfo());
        setSavingAssetClasses(false);
      })
      .catch((e) => {
        setToastMessage({
          message: getErrorMessage(e),
          visible: true,
          type: 'error',
        });
        setSavingAssetClasses(false);
      });
  };

  const clickSaveMapAssetClasses = () => {
    saveMapping(
      transform(
        selected,
        (mapping, item) => {
          mapping[item.id] = selectedItemsAssetClassId;
        },
        {} as IdMapping,
      ),
    );
  };

  useEffect(() => {
    const byId = keyBy(items, (x) => x.id);
    setSelected(compact(selected.map((x) => byId[x.id])));
  }, [items]);

  const enableSaveSelected = useMemo(
    () =>
      selectedItemsAssetClassId &&
      some(selected, (x) => x.commonClassId !== selectedItemsAssetClassId),
    [selected, selectedItemsAssetClassId],
  );

  return (
    <>
      {toastMessage?.visible && (
        <div className={style.toast}>
          <ToastMessage
            className='successful'
            status={toastMessage}
            visiableHandler={(value) =>
              setToastMessage({...toastMessage, visible: value})
            }
          />
        </div>
      )}

      <Table
        id='globalAssetMapping'
        rows={items}
        onQueryChanged={(query) => {
          lastQueryRef.current = query;
          getData(query)
        }}
        paginate
        sortable
        selectable={{selected, onChange: setSelected}}
        searchable='Search asset classes...'
        totalRows={total}
        dynamicColumns
        loading={fetchingData}
        updatingTableTopbar={savingAssetClasses}
      >
        {/* Top Bar */}
        <TopBar>
          {selected.length > 0 && (
            <div className={style['selected-items-action']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Common}
                tooltipKey='Map selected asset classes'
              >
                <label>Map selected asset classes ({selected.length}) to</label>
              </TooltipWrapper>
              <FieldSelect
                classnames={style['field-select']}
                labelText=''
                options={standardisedClassOptions}
                selectId={selectedItemsAssetClassId}
                onSelect={(selectedOption) => {
                  setSelectedItemsAssetClassId(
                    selectedOption?.value ? _.toNumber(selectedOption?.value) : null
                  );
                }}
              />
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save'
              >
                <button
                  className='primary'
                  disabled={savingAssetClasses || !enableSaveSelected}
                  onClick={() => clickSaveMapAssetClasses()}
                >
                  Save
                  {savingAssetClasses ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  className='secondary'
                  onClick={() => {
                    setSelected([]);
                    setSelectedItemsAssetClassId(null);
                  }}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          )}
        </TopBar>

        {/* Status column */}
        <Column
          id='status'
          label='Status'
          accessor={(row) => (isNil(row.commonClassId) ? 'Unmapped' : 'Mapped')}
          filterable
          editor={{
            type: InputType.Select,
            options: StatusOptions,
            nullOption: 'All',
            searchable: true,
          }}
        >
          {(type: string) => (
            <span className={classNames('badge-status', type.toLowerCase())}>
              {type}
            </span>
          )}
        </Column>

        {/* Name column */}
        <Column
          label='Asset Class'
          prop='name'
          filterable={{
            type: InputType.Select,
            options: assetClassOptions,
            getLabel: (x) => (x as GlobalAssetMapping).name,
            getValue: (x) => (x as GlobalAssetMapping).id,
            multi: true,
            searchable: true,
          }}
        ></Column>

        {/* Standard class column */}
        <Column
          id='commonClass.name'
          label='Avalara Standardized Asset Class'
          prop='commonClassId'
          editable
          filterable={{multi: true, searchable: true, nullOption: false}}
          editor={{
            type: InputType.Select,
            options: standardClasses.list,
            nullOption: false,
            getLabel: (x) => (x as AssetClass).name,
            getValue: (x) => (x as AssetClass).id,
            disabled: savingAssetClasses,
          }}
          onChange={(newId, row) =>
            saveMapping({
              [row.id]: newId as number,
            })
          }
        ></Column>

        {/* Description column */}
        <Column
          id='commonClass.description'
          label='Avalara standardized asset class description'
          accessor={(row) =>
            get(standardClasses.byId, row.commonClassId || '')?.description
          }
        ></Column>
      </Table>

      <Loader isOpen={fetchingCommonAssetClass} />
    </>
  );
}

export default FormsGlobalAssetMapping;
