import React, {useState, useEffect} from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../../../components/FieldInput/FieldInput';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import FieldDate from '../../../components/FieldDate/FieldDate';
import FieldToggleCheckbox from '../../../components/FieldToggleCheckbox/FieldToggleCheckbox';
import FieldSelectYear from '../../../components/FieldSelectYear/FieldSelectYear';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import validateEmpty from '../../../utils/validateEmpty';
import {
  AssessorAccount,
  Assessor,
  Company,
  ExtProperty,
  IErrorBase,
  ILegalEntity,
  IToastMessage,
  Property,
  ReduxState,
  SelectOption,
  State,
  TaxYear,
} from '../../../interface';
import {
  getAssessor,
  getAssessors,
  getCompanies,
  getLegalEntities,
  getProperty,
  getUploadFile,
  setProperties,
  updateProperty,
  updateUploadFile,
} from '../../../services';
import style from './PropertySetup.module.scss';
import {connect} from 'react-redux';
import {AxiosResponse} from 'axios';
import clearProperty from '../../../utils/clearProperty';
import removeEmptyField from '../../../utils/removeEmptyField';
import {Dispatch} from 'redux';
import actions from '../../../redux/actions';
import {ISO_DATE, formatDateFromBackend} from '../../../utils/date';
import {SEARCHABLELIMIT} from '../../../constants';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import {useAppDispatch} from '../../../redux/store';
import {getDashboardInfo} from '../../../redux/features/dashboard-info-slice';
import useSelectedSystemTaxYear from '../../../hook/useSelectedSystemTaxYear';

// Init formik values of account
const newAccount = {
  expanded: true,
  number: '',
  assessorId: null,
  name: '',
  alternateReference: '',
  glAccountNumberInheritFromProperty: false,
  glAccountNumber: '',
  costCenterInheritFromProperty: false,
  costCenter: '',
  legalDescription: '',
  renditionNotes: '',
  taxYears: [],
  selectedTaxYearId: null,
  active: true,
  showOptional: false,
};

// Init formik values
const newInit = {
  companyId: null,
  legalEntities: [],
  legalEntityId: null,
  number: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    stateId: null,
    postalCode: '',
  },
  name: '',
  doingBusinessAs: '',
  costCenter: '',
  openDate: '',
  closedDate: '',
  glAccountNumber: '',
  description: '',
  active: true,
  assessors: [],
  assessorAccounts: [newAccount],
  showOptional: false,
};

// Define the required fields
const propertyRequiredFields = [
  'companyId',
  'name',
  'number',
  'address.stateId',
  'legalEntityId'
];

const accountRequiredFields = [
  'number',
  'assessorId'
];

interface Props {
  states: State[];
  storeProperties: FormikValues;
  setStoreProperties: (value: FormikValues | undefined) => void;
}

function PropertySetup(props: Props) {
  const dispatch = useAppDispatch();
  const {states, storeProperties, setStoreProperties} = props;
  // ID is use to find out page is add or edit status
  // const [block, setBlock] = useState(false);
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  const uploadMatch = useMatch('/properties/editUploadProperty/*');
  const propertyId = searchParams.get('itemId');
  const location = useLocation();
  const [navBlock, setNavBlock] = useState<boolean>(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Properties',
      href: '/properties?tab=Properties+and+Assessor+Accounts',
    },
    {value: 'Property Setup', href: '/properties/propertyUpload'},
    {value: id ? 'Edit Property' : 'Add Property Manually'},
  ];

  const [statincorporation, setstatincorporation] = useState<SelectOption[]>(
    [],
  );
  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  // Options of select fields
  const [companylist, setcompanylist] = useState<Company[]>([]);
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [fetchingUploadFile, setFetchingUploadFile] = useState(false);
  const [fetchingAssessors, setFetchingAssessors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [legalEntities, setLegalEntities] = useState<ILegalEntity[]>([]);

  const navigate = useNavigate();
  // Flag for submit or not
  const [issubmit, setissubmit] = useState<boolean>(false);
  const [submitToast, setSubmitToast] = useState<boolean>(false);
  const [initialValues, setinitialValues] = useState<Property>(
    newInit as unknown as Property,
  );

  let NAVIGATE = '';
  if (id) {
    if (uploadMatch) {
      NAVIGATE = `/properties/propertyReview/${id}`;
    } else {
      NAVIGATE = `/properties?tab=Properties+and+Assessor+Accounts`;
    }
  } else {
    NAVIGATE = `/properties/propertyUpload`;
  }

  function handlePromise(
    promise: Promise<AxiosResponse>,
    dispathDashboard: boolean,
  ) {
    promise
      .then((e: AxiosResponse<IErrorBase>) => {
        if (_.inRange(e.status, 200, 299)) {
          dispathDashboard && dispatch(getDashboardInfo());
          navigate(NAVIGATE);
        } else {
          setToastMessage({
            visible: true,
            message: `Error occurs, please try again later.`,
            type: 'error',
          });
        }
        setSubmitting(false);
      })
      .catch((e) => {
        setToastMessage({
          visible: true,
          message: e?.data?.errors?.length
            ? e?.data.errors[0].replace(/items\.\d\./, '')
            : `Error occurs, please try again later.`,
          type: 'error',
        });
        setSubmitting(false);
      });
  }

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => {
      // Validate form & validate account form
      if (issubmit) {
        const _propertyRequired = _.cloneDeep(propertyRequiredFields);
        const _accountRequired = _.cloneDeep(accountRequiredFields);
        // Validate account fields
        const accountError = _.map(values.assessorAccounts, (account) => {
          return _.defaults(validateEmpty(_.pick(account, _accountRequired)));
        });
        const errors = {
          ..._.defaults(validateEmpty(_.pick(values, _propertyRequired))),
          assessorAccounts: accountError,
        };
        const result = _.every(_.values(errors), (e: string | []) => {
          if (_.isObject(e)) {
            return _.every(_.values(e), (se) => {
              return _.every(se, _.isEmpty);
            });
          } else {
            return _.every(e, _.isEmpty);
          }
        });
        if (!result) {
          if (submitToast) {
            setSubmitToast(false);
            setToastMessage({
              visible: true,
              message:
                'The Property you save had some errors that need to be fixed. Please address these to move forward.',
              type: 'error',
            });
          }
          return errors;
        }
      }
    },
    onSubmit: () => {
      setNavBlock(false);
      const {assessorAccounts, address, ...rest} = formik.values;
      for (const assessorAccount of assessorAccounts) {
        if (!assessorAccount.taxYear) {
          assessorAccount.taxYear = assessorAccount.taxYears[0].taxYear;
        }
      }
      const values = _.omit(
        _.assign(rest, {
          assessorAccounts: _.map(assessorAccounts, (assessorAccount) =>
            _.omit(
              _.pickBy(assessorAccount, (v) =>
                !_.isBoolean(v) ? _.identity(v) : true,
              ),
              'selectedTaxYearId',
              'taxYears',
              'expanded',
              'showOptional',
            )
          ),
          address: _.pickBy(address, (v) =>
            // Omit null values from address
            !_.isBoolean(v) ? _.identity(v) !== null : true,
          ),
        }),
        'assessors',
        'legalEntities',
        'expanded',
        'showOptional',
      );
      if (id) {
        if (uploadMatch) {
          const middleware = _.concat(
            _.map(values.assessorAccounts, (assessorAccount) => ({
              ...values,
              assessorAccounts: [assessorAccount],
              id: assessorAccount.propertyId,
            })),
            items,
          );
          const part = _.partition(removeEmptyField(middleware), 'id');
          const origin = _.uniqBy(part[0], 'id');
          const addition = _.map(part[1], (item, index) => ({
            ...item,
            id: _.max(_.map(origin, 'id')) + index + 1,
          }));
          setSubmitting(true);
          handlePromise(
            updateUploadFile(id, {
              items: _.concat(origin, addition),
            }),
            false,
          );
        } else {
          setSubmitting(true);
          handlePromise(updateProperty(id, values), true);
        }
      } else {
        setSubmitting(true);
        handlePromise(
          setProperties({
            items: [
              clearProperty(
                _.pickBy(values, (v) =>
                  !_.isBoolean(v) ? _.identity(v) : true,
                ) as ExtProperty,
              ),
            ],
          }),
          true,
        );
      }
    },
  });

  // Get options of select from JSON
  useEffect(() => {
    setstatincorporation(
      states.map((o: State) => ({
        value: o['id'],
        label: o['abbreviation'],
        name: o['name'],
      })),
    );
  }, [states]);

  useEffect(() => {
    setFetchingCompanies(true);
    getCompanies()
      .then((d) => {
        setcompanylist(d.data.items);
        if (_.isEqual(d.data.total, 1)) {
          formik.setFieldValue('companyId', d.data.items[0].id);
        }
        setFetchingCompanies(false);
      })
      .catch(() => {
        setFetchingCompanies(false);
      });

    setFetchingAllLegalEntities(true);
    getLegalEntities()
      .then((d) => {
        setLegalEntities(d.data.items);
        setFetchingAllLegalEntities(false);
      })
      .catch(() => {
        setFetchingAllLegalEntities(false);
      });
    if (!_.isEmpty(storeProperties)) {
      formik.setValues(storeProperties);
      setStoreProperties(undefined);
    }
  }, []);

  // Add & delete account form
  const addAccount = () => {
    const assessorAccounts = _.concat(formik.values.assessorAccounts, [_.cloneDeep(newAccount)]);
    const initvalues = {...formik.values, assessorAccounts};
    setinitialValues(initvalues as Property);
  };
  const deleteAccount = (accountIndex: number) => {
    const assessorAccounts = [
      ..._.slice(formik.values.assessorAccounts, 0, accountIndex),
      ..._.slice(formik.values.assessorAccounts, accountIndex + 1),
    ];
    const initvalues = {...formik.values, assessorAccounts};
    setinitialValues(initvalues as Property);
  };

  //Edit upload property

  const [property, setproperty] = useState<ExtProperty>();
  const [items, setItems] = useState<ExtProperty[]>([]);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);
  const [fetchingAllLegalEntities, setFetchingAllLegalEntities] =
    useState(false);

  function setOptions(_items: Assessor[]) {
    return _items.map((item: Assessor) => ({
      label: item.name,
      name: item.name,
      value: item.id,
    }));
  }

  //Get properties data from JSON,if page is edit status
  useEffect(() => {
    if (id) {
      if (uploadMatch) {
        setFetchingUploadFile(true);
        getUploadFile(id).then((d) => {
          const result = _.map(d.data.items, (item) => ({
            ...item,
            assessorAccounts: _.map(item.assessorAccounts, (account) => ({
              ...account,
              propertyId: item.id,
              expanded: true,
            })),
          }));
          const originProperty = _.find(result, (item) =>
            _.isEqual(_.toString(item['id']), propertyId),
          );
          const group = _.flatMap(
            _.map(
              _.filter(result, (item) => {
                if (
                  _.isEqual(item.companyId, originProperty?.companyId) &&
                  _.isEqual(
                    item.legalEntityId,
                    originProperty?.legalEntityId,
                  ) &&
                  _.isEqual(item.number, originProperty?.number) &&
                  _.isEqual(item.stateId, originProperty?.stateId) &&
                  !_.isEqual(item.id, originProperty?.id)
                ) {
                  return true;
                } else {
                  return false;
                }
              }),
              (item) => item.assessorAccounts,
            ),
          );
          setItems(
            _.reject(
              result,
              (item) =>
                _.includes(_.map(group, 'propertyId'), item.id) ||
                _.isEqual(originProperty?.id, item.id),
            ),
          );
          const final = _.mapValues(originProperty, (v, k) => {
            if (_.isEqual(k, 'assessorAccounts')) {
              return _.concat(v, group);
            }
            return v;
          }) as ExtProperty;
          if (final) {
            if (final?.address?.stateId) {
              getAssessors({
                stateId: final.address.stateId,
              })
                .then((asyncAssessors) => {
                  _.set(
                    final,
                    'assessors',
                    setOptions(asyncAssessors.data.items),
                  );
                  setproperty(_.defaultsDeep(final, newInit));
                  setissubmit(true);
                  setSubmitToast(true);
                  setFetchingUploadFile(false);
                })
                .catch(() => {
                  setFetchingUploadFile(false);
                });
            } else {
              _.set(final, 'address.stateId', '');
              setproperty(_.defaultsDeep(final, newInit));
              setissubmit(true);
              setSubmitToast(true);
              setFetchingUploadFile(false);
            }
          } else {
            setissubmit(true);
            setSubmitToast(true);
            setFetchingUploadFile(false);
          }
        });
      } else {
        setFetchingUploadFile(true);
        getProperty(id).then((d) => {
          const result = d.data;
          if (result) {
            // CR-7389 only show assessor accounts for the selected tax year
            const taxyearAssessorAccounts = result.assessorAccounts.filter((assessorAccount: AssessorAccount) => assessorAccount.taxYear == (selectedSystemTaxYear.taxYear || 0));
            if (taxyearAssessorAccounts.length === 0) {
              result.assessorAccounts.push(newAccount);
            }
            if (result?.address?.stateId) {
              getAssessors({
                stateId: result.address.stateId,
              })
                .then((asyncAssessors) => {
                  _.set(
                    result,
                    'assessors',
                    setOptions(asyncAssessors.data.items),
                  );
                  setproperty(_.defaultsDeep(result, newInit));
                  setFetchingUploadFile(false);
                })
                .catch(() => {
                  setFetchingUploadFile(false);
                });
            } else {
              _.set(result, 'address.stateId', '');
              setproperty(_.defaultsDeep(result, newInit));
              setFetchingUploadFile(false);
            }
          } else {
            setFetchingUploadFile(false);
          }
        });
      }
    }
  }, [id, uploadMatch]);

  //Fill the input field if page is edit status
  useEffect(() => {
    if (!_.isEmpty(storeProperties)) {
      formik.setValues(storeProperties);
    } else {
      if (property) {
        const assessorIds = _.flatMapDeep(
          property.assessorAccounts.map((account) => account.assessorId),
        );
        const assessorTaxYears = assessorIds.map((_id) =>
          _id ? getAssessor(_id) : undefined,
        );
        setFetchingAllAssessors(true);
        Promise.all(assessorTaxYears)
          .then((d) => {
            d.forEach((item, index) => {
              if (item) {
                _.set(property.assessorAccounts[index], 'taxYears', item.data.taxYears);
                const selectedTaxYear = _.find(item.data.taxYears, taxYear => taxYear.taxYear === property.assessorAccounts[index].taxYear)
                  || _.find(item.data.taxYears, taxYear => taxYear.taxYear == selectedSystemTaxYear.taxYear)
                  || item.data.taxYears[0];
                _.set(property.assessorAccounts[index], 'selectedTaxYearId', selectedTaxYear.id);
                _.set(property.assessorAccounts[index], 'mailingAddresses', item.data.mailingAddresses);
              }
            });
            formik.setValues({...property, legalEntities: legalEntities});
            setFetchingAllAssessors(false);
          })
          .catch(() => {
            setFetchingAllAssessors(false);
          });
      }
    }
  }, [property, storeProperties, id]);

  useEffect(() => {
    formik.values.companyId
      ? formik.setFieldValue(
          `legalEntities`,
          _.filter(legalEntities, legalEntity =>  legalEntity.companyId == formik.values.companyId),
        )
      : formik.setFieldValue(`legalEntities`, legalEntities);
  }, [legalEntities, formik.values.companyId]);

  useEffect(() => {
    if (property) {
      setNavBlock(
        !_.isEqual(
          _.omit(
            _.assign({}, formik.values, {
              assessorAccounts: _.map(formik.values.assessorAccounts, (account) =>
                _.omit(account, 'expanded', 'showOptional'),
              ),
            }),
            'showOptional',
            'assessors',
            'legalEntities',
          ),
          _.omit(
            _.assign({}, property, {
              assessorAccounts: _.map(property.assessorAccounts, (account) =>
                _.omit(account, 'expanded', 'showOptional'),
              ),
            }),
            'showOptional',
            'assessors',
            'legalEntities',
          ),
        ),
      );
    } else {
      setNavBlock(
        !_.isEqual(
          _.omit(initialValues, 'legalEntities'),
          _.omit(formik.values, 'legalEntities'),
        ),
      );
    }
  }, [initialValues, formik.values, property]);

  function TitleWithTooltip() {
    if (!id) {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageTitle}
          tooltipKey='Manually Add Property'
        >
          <span>Manually Add Property</span>
        </TooltipWrapper>
      );
    } else {
      if (uploadMatch) {
        return (
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Edit Property'
          >
            <span>Edit Property</span>
          </TooltipWrapper>
        );
      } else {
        return (
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Edit Property Information'
          >
            <span>Edit Property Information</span>
          </TooltipWrapper>
        );
      }
    }
  }

  function getMailingAddress(assessorAccount: AssessorAccount) {
    let formattedMailingAddress = '';
    // Get mailing address for given tax year and type 'Returns'
    const mailingAddress = _.get(
      _.find(assessorAccount.mailingAddresses, { addressType: 'Returns' }),
      'address'
    );

    if (!mailingAddress) return '';

    // Get line 1
    formattedMailingAddress += `${_.get(mailingAddress, 'line1')} `;

    // Get line 2 if exists
    const line2 = _.get(mailingAddress, 'line2');
    if (line2) {
      formattedMailingAddress += `${line2}, `;
    }

    // Get city
    formattedMailingAddress += `${_.get(mailingAddress, 'city')}, `;

    // Get state if exists
    const state = _.get(_.get(mailingAddress, 'state'), 'name');
    if (state) {
      formattedMailingAddress += `${state} `;
    }

    // Get zip
    formattedMailingAddress += _.get(mailingAddress, 'postalCode');
    return formattedMailingAddress;
  }

  function getAssessorAccountCounter(assessorAccount: AssessorAccount) {
    const taxyearAccounts = formik.values.assessorAccounts.filter((account: AssessorAccount) => account.taxYear == (selectedSystemTaxYear.taxYear || 0) || !account.id);
    const counter = taxyearAccounts.findIndex((account: AssessorAccount) => account === assessorAccount);
    return Math.max(counter + 1, 1);
  }

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {/* Error message box,if there are errors message */}
        <ToastMessage status={toastMessage} className={style['toast']} />
        <h2> {TitleWithTooltip()}</h2>
        <form className={style['main']} onSubmit={formik.handleSubmit}>
          {/* Properties form list */}
          <div className={classNames(style['section'])}>
            <div className={style['inner']}>
              {/* Form header,remove icon only appear when form count > 1 */}
              <div className={style['header']}>
                <h3>
                  {formik.values.name ? formik.values.name : `Property 1`}
                </h3>
                <div className={style['operate']}>
                  <FieldToggleCheckbox
                    label='Active'
                    value={formik.values.active}
                    onChange={(value) => formik.setFieldValue(`active`, value)}
                    tooltipText='Property Active'
                  />
                </div>
              </div>
              <div className={style['form']}>
                {/* Select Property Owner Block */}
                <div className={style['block']}>
                  <fieldset>
                    <legend>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Common}
                        tooltipKey='Select Legal Entity'
                      >
                        <span>Select Legal Entity</span>
                      </TooltipWrapper>
                    </legend>
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldSelect
                          labelText='Company Name'
                          selectId={formik.values.companyId}
                          options={companylist.map(
                            (company) =>
                              ({
                                value: company.id,
                                label: company.name,
                                name: company.number,
                              } as SelectOption),
                          )}
                          noSearch={
                            _.gt(companylist.length, SEARCHABLELIMIT)
                              ? false
                              : true
                          }
                          onSelect={(value) => {
                            formik.setFieldValue(
                              `companyId`,
                              value?.value ? _.toNumber(value?.value) : null,
                            );
                            if (uploadMatch) {
                              formik.setFieldValue(
                                `companyName`,
                                value?.label ? value?.label : '',
                              );
                              formik.setFieldValue(
                                `companyNumber`,
                                value?.name ? value?.name : '',
                              );
                            }
                          }}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).companyId
                          }
                          required
                        />
                      </div>
                      <div className={style['column']}>
                        <FieldSelect
                          labelText='Legal Entity Name'
                          selectId={formik.values.legalEntityId}
                          options={_.map(
                            formik.values.legalEntities,
                            (item) => ({
                              value: item.id,
                              label: item.name,
                              name: item.number,
                            }),
                          )}
                          noSearch={
                            _.gt(
                              formik.values.legalEntities?.length || 0,
                              SEARCHABLELIMIT,
                            )
                              ? false
                              : true
                          }
                          onSelect={(value) => {
                            formik.setFieldValue(
                              `legalEntityId`,
                              value?.value ? _.toNumber(value?.value) : null,
                            );
                            formik.setFieldValue(
                              `legalEntityName`,
                              value?.label ? value?.label : null,
                            );
                            formik.setFieldValue(
                              `legalEntityNumber`,
                              value?.name ? value?.name : null,
                            );
                          }}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).legalEntityId
                          }
                          required
                        />
                      </div>
                      <div className={style['column']}></div>
                    </div>
                    <div className={style['buttons']}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.PageAction}
                        tooltipKey='Add new legal entity'
                      >
                        <button
                          type='button'
                          className={classNames(
                            'button secondary',
                            style['add-button'],
                          )}
                          onClick={() => {
                            setNavBlock(false);
                            setStoreProperties(formik.values);
                            setTimeout(() => {
                              navigate('../legalEntitySetup', {
                                state: location.pathname + location.search,
                              });
                            }, 100);
                            return false;
                          }}
                        >
                          Add new legal entity
                        </button>
                      </TooltipWrapper>
                    </div>
                  </fieldset>
                </div>
                <div className={style['block']}>
                  {/* Property Information Field */}
                  <fieldset>
                    <legend>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Common}
                        tooltipKey='Property Information'
                      >
                        <span>Property Information</span>
                      </TooltipWrapper>
                    </legend>
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldInput
                          name='name'
                          labelText='Property Name'
                          value={formik.values.name}
                          onChange={(value) =>
                            formik.setFieldValue(`name`, value)
                          }
                          maxLength={100}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).name
                          }
                          required
                        />
                      </div>
                      <div className={style['column']}>
                        <FieldInput
                          name='number'
                          labelText='Property Number'
                          value={formik.values.number}
                          onChange={(value) =>
                            formik.setFieldValue(`number`, value)
                          }
                          maxLength={50}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).number
                          }
                          required
                        />
                      </div>
                      <div className={style['column']}></div>
                    </div>
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldInput
                          name='address.line1'
                          labelText='Property Address Line 1'
                          value={formik.values.address?.line1}
                          onChange={(value) =>
                            formik.setFieldValue(`address.line1`, value)
                          }
                          maxLength={100}
                        />
                      </div>
                      <div className={style['column']}>
                        <FieldInput
                          name='address.line2'
                          labelText='Property Address Line 2'
                          value={formik.values.address?.line2}
                          onChange={(value) =>
                            formik.setFieldValue(`address.line2`, value)
                          }
                          maxLength={100}
                        />
                      </div>
                      <div className={style['column']}>
                        <div className={style['address-row']}>
                          <div className={style['address-column']}>
                            <FieldInput
                              name='address.city'
                              labelText='City'
                              value={formik.values.address?.city}
                              onChange={(value) =>
                                formik.setFieldValue(`address.city`, value)
                              }
                              maxLength={50}
                            />
                          </div>
                          <div className={style['address-column']}>
                            <FieldSelect
                              labelText='State'
                              options={statincorporation}
                              selectId={formik.values.address?.stateId}
                              noSearch={
                                _.gt(statincorporation.length, SEARCHABLELIMIT)
                                  ? false
                                  : true
                              }
                              onSelect={(value) => {
                                if (
                                  !_.isEqual(
                                    value?.value ?? null,
                                    formik.values.address?.stateId,
                                  )
                                ) {
                                  formik.setFieldValue(
                                    `address.stateId`,
                                    value?.value ? _.toNumber(value?.value) : null,
                                  );
                                  if (uploadMatch) {
                                    formik.setFieldValue(
                                      `address.state`,
                                      value,
                                    );
                                    formik.setFieldValue(
                                      `address.stateName`,
                                      value?.label,
                                    );
                                  }
                                  if (value?.value) {
                                    setFetchingAssessors(true);
                                    getAssessors({
                                      stateId: value?.value as number,
                                    })
                                      .then((d) => {
                                        formik.setFieldValue(
                                          `assessors`,
                                          d.data.items.map((item: Assessor) => ({
                                            value: item.id,
                                            name: item.name,
                                            label: item.name,
                                          })) as SelectOption[],
                                        );
                                        setFetchingAssessors(false);
                                      })
                                      .catch(() => {
                                        setFetchingAssessors(false);
                                      });
                                    _.map(formik.values.accounts, (__, idx) => {
                                      formik.setFieldValue(
                                        `assessorAccounts.${[idx]}.assessorId`,
                                        null,
                                      );
                                    });
                                  }
                                  else {
                                    formik.setFieldValue(
                                      `assessors`,
                                      [] as SelectOption[],
                                    );
                                  }
                                }
                              }}
                              error={
                                formik.errors &&
                                (formik.errors as FormikValues)?.address
                                  ?.stateId
                              }
                              required
                            />
                          </div>
                          <div className={style['address-column']}>
                            <FieldInput
                              name='address.postalCode'
                              labelText='Postal Code'
                              value={formik.values.address?.postalCode || ''}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  `address.postalCode`,
                                  value,
                                )
                              }
                              maxLength={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  {/* Property Optional Information Field */}
                  {formik.values.showOptional && (
                    <fieldset>
                      <legend>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Common}
                          tooltipKey='Property Optional Information'
                        >
                          <span>Property Optional Information</span>
                        </TooltipWrapper>
                      </legend>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <FieldInput
                            name='doingBusinessAs'
                            labelText='Doing Business As'
                            value={formik.values.doingBusinessAs}
                            onChange={(value) =>
                              formik.setFieldValue(`doingBusinessAs`, value)
                            }
                            maxLength={100}
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            name='costCenter'
                            labelText='Cost Center'
                            value={formik.values.costCenter}
                            onChange={(value) => {
                              formik.setFieldValue(`costCenter`, value);
                              _.each(
                                formik.values.assessorAccounts,
                                (account: AssessorAccount, idx: number) => {
                                  if (account.costCenter) {
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.costCenter`,
                                      value,
                                    );
                                  }
                                },
                              );
                            }}
                            maxLength={50}
                          />
                        </div>
                      </div>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <div className={style['row-date']}>
                            <div className={style['column-date']}>
                              <FieldDate
                                labelText='Open Date'
                                maxDate={
                                  formik.values.closedDate
                                    ? moment(formik.values.closedDate).format(
                                        ISO_DATE,
                                      )
                                    : undefined
                                }
                                selectValue={formik.values.openDate}
                                onSelect={(value) =>
                                  formik.setFieldValue(`openDate`, value)
                                }
                              />
                            </div>
                            <div className={style['column-date']}>
                              <FieldDate
                                labelText='Closed Date'
                                minDate={
                                  formik.values.openDate
                                    ? moment(formik.values.openDate).format(
                                        ISO_DATE,
                                      )
                                    : undefined
                                }
                                selectValue={formik.values.closedDate}
                                onSelect={(value) =>
                                  formik.setFieldValue(`closedDate`, value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            name='glAccountNumber'
                            labelText='GL Account Number'
                            value={formik.values.glAccountNumber}
                            onChange={(value) => {
                              formik.setFieldValue(`glAccountNumber`, value);
                              _.each(
                                formik.values.assessorAccounts,
                                (account: AssessorAccount, idx: number) => {
                                  if (
                                    account.glAccountNumberInheritFromProperty
                                  ) {
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.gLAccountNumber`,
                                      value,
                                    );
                                  }
                                },
                              );
                            }}
                            maxLength={50}
                          />
                        </div>
                        <div className={style['column']}></div>
                      </div>
                      <div className={style['row']}>
                        <div
                          className={classNames(
                            style['column'],
                            style['large-column'],
                          )}
                        >
                          <FieldInput
                            name='description'
                            labelText='Property Description'
                            value={formik.values.description}
                            onChange={(value) =>
                              formik.setFieldValue(`description`, value)
                            }
                            maxLength={100}
                          />
                        </div>
                        <div className={style['column']}></div>
                      </div>
                    </fieldset>
                  )}
                  {/* Fieldset */}
                  <div className={style['bottom-bar']}>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.PageAction}
                      tooltipKey='Property Optional Information fields'
                    >
                      <i
                        role='button'
                        className={classNames('link', style['toggle-link'])}
                        onClick={() =>
                          formik.setFieldValue(
                            `showOptional`,
                            !formik.values.showOptional,
                          )
                        }
                      >
                        {`${
                          formik.values.showOptional ? 'Hide' : 'Show'
                        } Optional Information fields`}
                      </i>
                    </TooltipWrapper>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Required field'
                    >
                      <span className={style['required']}>* Required field</span>
                    </TooltipWrapper>
                  </div>
                </div>
                {/* Fieldset */}
              </div>
            </div>
            {/* Account form */}
            {formik.values.assessorAccounts && formik.values.assessorAccounts.map((assessorAccount: AssessorAccount, idx: number) => (
              // CR-7389 only show assessor accounts for the selected tax year
              (assessorAccount.taxYear == (selectedSystemTaxYear.taxYear || 0) || !assessorAccount.id) &&
              <div
                key={idx}
                className={classNames(style['inner-account'], {
                  [style['last']]: _.isEqual(
                    idx + 1,
                    formik.values.assessorAccounts.length,
                  ),
                  [style['collapsed']]: !assessorAccount.expanded,
                })}
              >
                {/* Form header,remove icon only appear when form count > 1 */}
                <div className={style['header']}>
                  <h3
                    onClick={() =>
                      formik.setFieldValue(
                        `assessorAccounts.${[idx]}.expanded`,
                        !assessorAccount.expanded,
                      )
                    }
                  >
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Account'
                    >
                      <span>
                        {assessorAccount.name ? assessorAccount.name : `Account ${ getAssessorAccountCounter(assessorAccount) }`}
                      </span>
                    </TooltipWrapper>
                  </h3>
                  <div className={style['operate']}>
                    <FieldToggleCheckbox
                      label='Active'
                      value={assessorAccount.active}
                      onChange={(value) =>
                        formik.setFieldValue(`assessorAccounts.${[idx]}.active`, value)
                      }
                      tooltipText='Account Active'
                    />
                    <i
                      className={style['edit']}
                      role='button'
                      onClick={() =>
                        formik.setFieldValue(`assessorAccounts.${[idx]}.expanded`, true)
                      }
                    ></i>
                    {formik.values.assessorAccounts.length > 1 && (
                      <i
                        className={style['remove']}
                        role='button'
                        onClick={() => deleteAccount(idx)}
                      ></i>
                    )}
                  </div>
                </div>
                
                <div className={style['form']}>
                  {/* Account base information field */}
                  <fieldset>
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldInput
                          name={`assessorAccounts.${[idx]}.number`}
                          labelText='Account Number'
                          value={assessorAccount.number}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `assessorAccounts.${[idx]}.number`,
                              value,
                            )
                          }
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).assessorAccounts &&
                            (formik.errors as FormikValues).assessorAccounts[idx].number
                          }
                          maxLength={100}
                          required
                        />
                      </div>
                      <div className={style['column']}>
                        <FieldSelect
                          labelText='Assessor'
                          selectId={assessorAccount.assessorId}
                          options={formik.values.assessors}
                          noSearch={
                            _.gt(
                              formik.values.assessors.length,
                              SEARCHABLELIMIT,
                            )
                              ? false
                              : true
                          }
                          onSelect={(value) => {
                            formik.setFieldValue(
                              `assessorAccounts.${[idx]}.assessorId`,
                              value?.value ? _.toNumber(value?.value) : null,
                            );
                            if (value?.value) {
                              setFetchingAllAssessors(true);
                              getAssessor(_.toNumber(value?.value))
                                .then((d) => {
                                  if (!_.isEmpty(d.data)) {
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.taxYears`,
                                      d.data.taxYears,
                                    );
                                    // For Assessor Information tax year dropdown, default to selected SystemTaxYear and fall back to first AssessorTaxYear
                                    const selectedTaxYear = _.find(
                                      d.data.taxYears, taxYear => taxYear.taxYear == selectedSystemTaxYear.taxYear
                                    ) || d.data.taxYears[0];
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.selectedTaxYearId`,
                                      selectedTaxYear.id,
                                    );
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.taxYear`,
                                      selectedTaxYear.taxYear,
                                    );
                                    formik.setFieldValue(
                                      `assessorAccounts.${[idx]}.mailingAddresses`,
                                      d.data.mailingAddresses,
                                    );
                                  }
                                  setFetchingAllAssessors(false);
                                })
                                .catch(() => {
                                  setFetchingAllAssessors(false);
                                });
                            }
                            if (uploadMatch) {
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.assessorName`,
                                value?.name ? value?.name : '',
                              );
                            }
                          }}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).assessorAccounts &&
                            (formik.errors as FormikValues).assessorAccounts[idx]
                              ?.assessorId
                          }
                          required
                        />
                        <p className={style['note']}>
                          Couldn’t find assessor you’re looking for?{' '}
                          <a
                            href='mailto:support@crowdreason.com'
                            className='link'
                          >
                            Contact Us
                          </a>
                        </p>
                      </div>
                      <div className={style['column']}></div>
                    </div>
                  </fieldset>
                  {/* Assessor Information field,it appear when user select assessor */}
                  {!_.isEmpty(assessorAccount.taxYears) && !!assessorAccount.assessorId && (
                    <fieldset>
                      <legend>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Common}
                          tooltipKey='Assessor Information'
                        >
                          <span>Assessor Information</span>
                        </TooltipWrapper>
                      </legend>
                      <div className={style['list']}>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Tax Year'
                          >
                            <span>Tax Year</span>
                          </TooltipWrapper>
                          <small>
                            {_.get(
                              _.find(assessorAccount.taxYears, [
                                'id',
                                assessorAccount.selectedTaxYearId,
                              ]),
                              'taxYear',
                            )}
                          </small>
                        </div>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Return deadline'
                          >
                            <span>Return deadline</span>
                          </TooltipWrapper>
                          <small>
                            {formatDateFromBackend(
                                _.get(
                                _.find(assessorAccount.taxYears, [
                                  'id',
                                  assessorAccount.selectedTaxYearId,
                                ]),
                                'returnsDeadline',
                              ), 'MM/DD/YYYY'
                            )}
                          </small>
                        </div>

                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Extension Offered'
                          >
                            <span>Extension Offered</span>
                          </TooltipWrapper>
                          <small>
                            {_.get(
                              _.find(assessorAccount.taxYears, [
                                'id',
                                assessorAccount.selectedTaxYearId,
                              ]),
                              'extensionsOffered',
                            )
                              ? 'Yes'
                              : 'No'}
                          </small>
                        </div>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Extension filing deadline'
                          >
                            <span>Extension filing deadline</span>
                          </TooltipWrapper>
                          <small>
                            {formatDateFromBackend(
                                _.get(
                                _.find(assessorAccount.taxYears, [
                                  'id',
                                  assessorAccount.selectedTaxYearId,
                                ]),
                                'extensionsDeadline',
                                ), 'MM/DD/YYYY'
                            )}
                          </small>
                        </div>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Return deadline if extended'
                          >
                            <span>Return deadline if extended</span>
                          </TooltipWrapper>
                          <small>
                            {formatDateFromBackend(
                              _.get(
                                _.find(assessorAccount.taxYears, [
                                  'id',
                                  assessorAccount.selectedTaxYearId,
                                ]),
                                'returnsDeadlineIfExtended',
                                ), 'MM/DD/YYYY'
                            )}
                          </small>
                        </div>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Assessor Web Address'
                          >
                            <span>Assessor Web Address</span>
                          </TooltipWrapper>
                          <small>
                            {(() => {
                              const website = 
                              _.get(
                                _.find(assessorAccount.taxYears,
                                  ['id',
                                  assessorAccount.selectedTaxYearId]),
                                'website'
                              );
                              
                              return website && (
                                <a 
                                  href={website.match(/^https?:\/\//i) ? website : `https://${website}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className='assessor-link'
                                >
                                  {website}
                                </a>
                              );
                            })()}
                          </small>
                        </div>
                        <div className={style['item']}>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Assessor Mailing Address'
                          >
                            <span>Assessor Mailing Address</span>
                          </TooltipWrapper>
                          <small
                            title={getMailingAddress(assessorAccount)}
                          >
                            {getMailingAddress(assessorAccount)}
                          </small>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* Account Optional Information Fieldset */}
                  {assessorAccount.showOptional && (
                    <fieldset>
                      <legend>Account Optional Information</legend>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.name`}
                            labelText='Account Name'
                            value={assessorAccount.name}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.name`,
                                value,
                              )
                            }
                            maxLength={100}
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.alternateReference`}
                            labelText='Alternate References'
                            value={assessorAccount.alternateReference as string}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.alternateReference`,
                                value,
                              )
                            }
                          />
                        </div>
                        <div className={style['column']}></div>
                      </div>
                      <div
                        className={classNames(
                          style['row'],
                          style['row-with-checkbox'],
                        )}
                      >
                        <div className={style['column']}>
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.glAccountNumber`}
                            labelText='GL Account Number'
                            value={assessorAccount.glAccountNumber}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.glAccountNumber`,
                                value,
                              )
                            }
                            maxLength={50}
                            disabled={
                              assessorAccount.glAccountNumberInheritFromProperty
                            }
                          />
                          <CustomCheckbox
                            label='Inherit from property'
                            value={assessorAccount.glAccountNumberInheritFromProperty}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `assessorAccounts.${[
                                  idx,
                                ]}.glAccountNumberInheritFromProperty`,
                                value,
                              );
                              if (value) {
                                formik.setFieldValue(
                                  `assessorAccounts.${[idx]}.glAccountNumber`,
                                  formik.values.glAccountNumber,
                                );
                              }
                            }}
                            tooltipText='GL Account Number Inherit from property'
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.costCenter`}
                            labelText='Cost Center'
                            value={assessorAccount.costCenter}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.costCenter`,
                                value,
                              )
                            }
                            maxLength={50}
                            disabled={assessorAccount.costCenterInheritFromProperty}
                          />
                          <CustomCheckbox
                            label='Inherit from property'
                            value={assessorAccount.costCenterInheritFromProperty}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `assessorAccounts.${[
                                  idx,
                                ]}.costCenterInheritFromProperty`,
                                value,
                              );
                              if (value) {
                                formik.setFieldValue(
                                  `assessorAccounts.${[idx]}.costCenter`,
                                  formik.values.costCenter,
                                );
                              }
                            }}
                            tooltipText='Cost Center Inherit from property'
                          />
                        </div>
                        <div className={style['column']}></div>
                      </div>
                      <div className={style['row']}>
                        <div
                          className={classNames(
                            style['column'],
                            style['large-column'],
                          )}
                        >
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.legalDescription`}
                            labelText='Legal Description'
                            value={formik.values.assessorAccounts[idx].legalDescription}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.legalDescription`,
                                value,
                              )
                            }
                            maxLength={100}
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            name={`assessorAccounts.${[idx]}.renditionNotes`}
                            labelText='Rendition Notes'
                            value={formik.values.assessorAccounts[idx].renditionNotes}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `assessorAccounts.${[idx]}.renditionNotes`,
                                value,
                              )
                            }
                            maxLength={255}
                          />
                        </div>
                      </div>
                    </fieldset>
                  )}
                  <div className={style['bottom-bar']}>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.PageAction}
                      tooltipKey='Account Optional Information fields'
                    >
                      <i
                        role='button'
                        className={classNames('link', style['toggle-link'])}
                        onClick={() =>
                          formik.setFieldValue(
                            `assessorAccounts.${[idx]}.showOptional`,
                            !assessorAccount.showOptional,
                          )
                        }
                      >
                        {`${
                          assessorAccount.showOptional ? 'Hide' : 'Show'
                        } Optional Information fields`}
                      </i>
                    </TooltipWrapper>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Required field'
                    >
                      <span className={style['required']}>* Required field</span>
                    </TooltipWrapper>
                  </div>
                </div>
              </div>
            ))}
            {/* Add Account button */}
            <div className={style['add-account']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Add Another Account'
              >
                <i
                  role='button'
                  className={classNames('link', style['add-link'])}
                  onClick={() => {
                    addAccount();
                  }}
                >
                  Add Another Account
                </i>
              </TooltipWrapper>
            </div>
          </div>
          <div className={style['footer']}>
            {/* Submit link,it change the flag of submit */}
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save and complete'
              >
                <button
                  type='submit'
                  className='primary'
                  onClick={() => {
                    setissubmit(true);
                    setSubmitToast(true);
                  }}
                  disabled={submitting}
                >
                  Save and complete
                  {submitting ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  type='button'
                  className='button secondary'
                  disabled={submitting}
                  onClick={() => {
                    navigate(NAVIGATE);
                  }}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </form>
      </div>
      <DiscardChangeConfirmModal
        initialData={{}}
        inputData={{}}
        navBlock={navBlock}
      />

      <Loader
        isOpen={
          fetchingCompanies ||
          fetchingUploadFile ||
          fetchingAssessors ||
          fetchingAllAssessors ||
          fetchingAllLegalEntities
        }
      />
    </>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
  storeProperties: state.properties,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStoreProperties: (value: FormikValues | undefined) =>
    dispatch(actions.setProperties(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertySetup);
