import _, { keys, last, values, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TOOLTIP_SECTIONS, USER_ROLES } from '../../../enums';
import {
  ExtUserModal,
  PurchaseReturn,
  SelectOption,
  UserModal,
} from '../../../interface';
import {
  getErrorMessage,
  getUsersAsAdmin,
  getPurchasedReturns,
  restoreUser,
} from '../../../services';
import { formatDate } from '../../../utils/stringUtil';
import { InputType } from '../../../components/Table/components/Editor/Editor';
import InitTable, { Query } from '../../../components/Table/Table';
import style from './UsersManagementAdmin.module.scss';
import TableMenu, { MenuItem } from '../../../components/TableMenu/TableMenu';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import classNames from 'classnames';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';

const { Table, Column } = InitTable<UserModal>();

interface Props {
  className?: string;
}

function UsersManagementAdmin({ className }: Props) {
  const [userList, setUserList] = useState<ExtUserModal[]>([]);
  const toastRef = useRef<ToastMessageRefType | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [isConfirmRestoreOpen, setIsConfirmRestoreOpen] = useState(false);
  const [confirmingRestore, setConfirmingRestore] = useState(false);
  const [toRestoreUser, setToRestoreUser] = useState<UserModal | null>(null);
  const lastQuery = useRef<Query>();
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingCustomerData, setFetchingCustomerData] = useState(false);
  const [query, setQuery] = useState<Query>({});

  const [customerAccounts, setCustomerAccounts] = useState<Array<{ value: string; label: string }>>([]);


  const fetchData = useCallback(() => {
    setFetchingData(true);
    return getUsersAsAdmin({
      page: query.pagination?.page,
      perPage: query.pagination?.pageSize,
      sortBy: last(keys(query.order)),
      sortDirection: last(values(query.order)),
      text: query.search,
      roles: (query.filter?.roles as string) ?? '',
      createdAtFrom: (query.filter?.createdAt as string[])?.[0] ?? '',
      createdAtTo: (query.filter?.createdAt as string[])?.[1] ?? '',
      status: (query.filter?.status as string) ?? '',
      customerAccountIds:
        (query.filter?.customerAccountIds as unknown[])?.join(
          ',',
        ) ?? '',
    })
      .then((d) => {
        setUserList(
          _.map(d.data.items, (item) => ({ ...item, checked: false }))
        );
        setTotal(d.data.total);

        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });
  }, [query]);

  const fetchCustomerData = useCallback(() => {
    setFetchingCustomerData(true);
    return getPurchasedReturns({
      page: 1,
      perPage: 1000,
    })
      .then(({ data }) => {
        
        setCustomerAccounts(data.items.map( 
          (item: PurchaseReturn) => ({
          id: item.id?.toString() ?? '',
          label: item.businessName,
          name: item.businessName,
          value: item.id?.toString() ?? '',
        })));
        setFetchingCustomerData(false);
      })
      .catch(() => {
        setFetchingCustomerData(false);
      });
  }, []);

  function getMenuItems(userData: UserModal) {
    const restoreUser = {
      Restore: () => {
        if (userData.status === 'Active') {
          toastRef.current?.showErrorToast('User is already active');
          return;
        }

        setToRestoreUser(userData);
        setIsConfirmRestoreOpen(true);
      },
    };

    const actions = [_.assign({}, restoreUser)];
    return [
      _.map(
        _.assign({}, ..._.compact(actions)),
        (onClick, label) =>
          ({
            variant: 'default',
            tooltip: {
              section: TOOLTIP_SECTIONS.TableRowAction,
              keyword: label,
            },
            label,
            onClick,
          } as MenuItem)
      ),
    ];
  }

  const confirmRestore = () => {
    if (toRestoreUser) {
      setConfirmingRestore(true);
      restoreUser(toRestoreUser.id)
        .then((d) => {
          const resError = getErrorMessage(d);
          if (resError) {
            toastRef.current?.showErrorToast(resError);
          } else {
            toastRef.current?.showSuccessToast('Restore user successfully');
            fetchData();
          }
          setConfirmingRestore(false);
          setIsConfirmRestoreOpen(false);
          setToRestoreUser(null);
        })
        .catch((err) => {
          setConfirmingRestore(false);
          setIsConfirmRestoreOpen(false);
          setToRestoreUser(null);
          toastRef.current?.showErrorToast(getErrorMessage(err));
        });
    }
  };

  useEffect(() => {
    if (!isEqual(query, lastQuery.current) && !_.isEmpty(query)) {
      fetchData();
    }
  }, [query]);

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const setQueryHandle = (x: Query) => {
    setQuery(x);
  };

  return (
    <>
      <div className={style['main-content']}>
        <h3>Users Management</h3>

        <div className={style['table-layout']}>
          <ToastMessage ref={toastRef} className={style['toast']} />
          <Table
            id='userManagement'
            rows={userList}
            onQueryChanged={(x) => setQueryHandle(x)}
            paginate
            sortable
            searchable='Search...'
            totalRows={total}
            dynamicColumns
            loading={fetchingData}
          >
            {/* Name column */}
            <Column label='Name' prop='fullName' />

            {/* Email column */}
            <Column label='Email' prop='email' />

            {/* Role column */}
            <Column
              label='Roles'
              prop='roles'
              filterable
              editor={{
                type: InputType.Select,
                options: USER_ROLES,
                nullOption: 'All',
              }}
              sortable={false}
            >
              {(roles: string[]) =>
                _.map(roles, (role, key) => <div key={key}>{role}</div>)
              }
            </Column>

            {/* Date column */}
            <Column
              label='Date Added'
              prop='createdAt'
              filterable
              editor={{
                type: InputType.DateRange,
                label: ['From Date', 'To Date'],
              }}
            >
              {formatDate}
            </Column>

            {/* Customer Account */}
            <Column
              id='customerAccountIds'
              filterable={{
                type: InputType.Select,
                getValue: (x) => (x as SelectOption).value,
                getLabel: (x) => (x as SelectOption).label,
                options: customerAccounts,
                multi: true,
                searchable: true,
              }}
              sortable={false}
              label='Customer Account'
              prop='userAccount.businessName'
            />

            {/* Status */}
            <Column
              id='status'
              label='Status'
              filterable
              sortable={false}
              editor={{
                type: InputType.Select,
                options: ['Active', 'Inactive'],
              }}
            >
              {(row: UserModal) => (
                <span
                  className={classNames(
                    'badge-status',
                    row.status === 'Active' ? 'active' : 'inactive'
                  )}
                >
                  {row.status}
                </span>
              )}
            </Column>

            {/* Actions column  */}
            <Column id='actions' label='Actions' sortable={false}>
              {(userData: UserModal) => (
                <div className={style['actions-column-cell']}>
                  <TableMenu
                    toggle={
                      <button type='button'>
                        <i className={style['icon-3-dots']} />
                      </button>
                    }
                    menuItems={getMenuItems(userData)}
                  />
                </div>
              )}
            </Column>
          </Table>
        </div>

        {/* Unfile confirm modal */}
        <ConfirmModal
          isOpen={isConfirmRestoreOpen}
          onRequestClose={() => {
            setIsConfirmRestoreOpen(false);
          }}
          title='Confirmation'
          content='Are you sure you want to restore?'
          confirming={confirmingRestore}
          onConfirm={() => {
            confirmRestore();
          }}
        />
      </div>
    </>
  );
}

export default UsersManagementAdmin;
