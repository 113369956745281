import classNames from 'classnames';
import React, {useState, useEffect, useMemo, useContext, useRef} from 'react';
import _ from 'lodash';
import {ExtProperty, ReduxState, State} from '../../interface';
import style from './ValidatePropertiesTable.module.scss';
import {useNavigate} from 'react-router-dom';
import useSearch from '../../hook/useSearch';
import {
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  PROPERTIESFILETOFIELD,
  TOOLTIP_SECTIONS,
  VEHICLEFILETOFIELD,
} from '../../enums';
import InitTable, {Query} from '../Table/Table';
import {InputType} from '../Table/components/Editor/Editor';
import {Dictionary} from '@reduxjs/toolkit';
import {connect} from 'react-redux';
import filterQuery from '../../utils/filterQuery';
import Modal from '../Modal/Modal';
import ModalContext from '../../context/ModalContext';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

const {Table, Column, TopBar} = InitTable<ExtProperty>();

type TypeColumnsKeys =
  | typeof FIXEDASSETFILETOFIELD
  | typeof INVENTORYFILETOFIELD
  | typeof VEHICLEFILETOFIELD
  | typeof LEASEDVEHICLEFILETOFIELD
  | typeof LEASEDEQUIPMENTFILETOFIELD
  | typeof PROPERTIESFILETOFIELD;

interface Props {
  dataList: ExtProperty[];
  standardColumns: string[];
  checkedColumns: string[];
  additionColumn: string[];
  mappedKeys: TypeColumnsKeys;
  type: string;
  url?: string;
  onDeleteItem: (value: ExtProperty[]) => void;
  states: State[];
  loading: boolean;
}

function ValidatePropertiesTable(props: Props) {
  const navigate = useNavigate();
  const {filteredData} = useSearch();
  const {
    dataList,
    standardColumns,
    additionColumn,
    checkedColumns,
    mappedKeys,
    type,
    url,
    onDeleteItem,
    states,
    loading,
  } = props;
  const [selected, setSelected] = useState<ExtProperty[]>([]);
  const [query, setQuery] = useState<Dictionary<Query>>({});
  //Filter
  const [options, setOptions] = useState<Dictionary<unknown[]>>({});
  const {state, dispatch} = useContext(ModalContext);
  const [deleteItem, setDeleteItem] = useState<ExtProperty>();

  const confirmDelete = () => {
    dispatch({type: 'OPEN', href: 'comfirm'});
  };
  const columns = useMemo(
    () => [...additionColumn, ...standardColumns],
    [standardColumns, additionColumn],
  );

  const defaultColumns = useMemo(
    () => new Set(checkedColumns),
    [checkedColumns],
  );

  useEffect(() => {
    setOptions((current) => ({
      ...current,
      status: _.compact(_.uniq(_.map(dataList, 'status'))),
      company: _.filter(
        _.uniqBy(
          _.map(dataList, (item) => ({
            name: _.get(item, 'companyName'),
            number: _.get(item, 'companyNumber'),
          })),
          'name',
        ),
        (item) => !_.isEmpty(item.name) || !_.isEmpty(item.number),
      ),
      legalEntity: _.filter(
        _.uniqBy(
          _.map(dataList, (item) => ({
            name: _.get(item, 'legalEntityName'),
            number: _.get(item, 'legalEntityNumber'),
          })),
          'name',
        ),
        (item) => !_.isEmpty(item.name) || !_.isEmpty(item.number),
      ),
      state: _.intersectionBy(
        states,
        _.map(dataList, (item) => ({id: item.address?.stateId})),
        'id',
      ),
      account: _.compact(_.uniq(_.map(dataList, 'assessorAccounts[0].number'))),
      assessor: _.compact(_.uniq(_.map(dataList, 'assessorAccounts[0].assessorName'))),
      property: _.compact(_.uniq(_.map(dataList, 'number'))),
    }));
  }, [dataList]);

  const result = _.orderBy(
    filteredData(
      filterQuery(dataList, query.property?.filter),
      _.lowerCase(query.property?.search),
    ),
    [
      _.keys(query.property?.order)[0]
        ? _.keys(query.property?.order)[0]
        : 'id',
    ],
    [
      _.values(query.property?.order)[0]
        ? _.values(query.property?.order)[0]
        : 'asc',
    ],
  );

  const chunk = _.chunk(result, query.property?.pagination?.pageSize)[
    (query.property?.pagination?.page as number) - 1
  ];

  const confirm = (e: React.MouseEvent) => {
    if (deleteItem?.id) {
      onDeleteItem(_.reject(dataList, ['id', deleteItem?.id]));
      setDeleteItem(undefined);
    } else {
      onDeleteItem(
        _.reject(dataList, (item) =>
          _.includes(_.map(selected, 'id'), item.id),
        ),
      );
    }
    dispatch({type: 'CLOSE'});
    setSelected([]);
    e.stopPropagation();
  };

  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
  }, [state.isOpen]);

  return (
    <div className={style['validate-properties']}>
      <Table
        id='uploadProperties'
        rows={chunk ? chunk : []}
        paginate
        selectable={{selected, onChange: setSelected}}
        searchable='Search properties...'
        onQueryChanged={(x) => setQuery({...query, [type]: x})}
        totalRows={result.length}
        dynamicColumns
        loading={loading}
      >
        <TopBar>
          <div className={style.operate}>
            {selected.length > 0 && (
              <>
                <button className='default-button' onClick={confirmDelete}>
                  Delete ({selected.length})
                </button>
              </>
            )}
          </div>
        </TopBar>
        {columns.map((col, idx) => (
          <Column
            key={idx}
            hidden={!defaultColumns.has(col)}
            id={_.get(mappedKeys, col)}
            label={col}
            sortable={true}
          >
            {(property: ExtProperty) => {
              const value = _.get(property, _.get(mappedKeys, col), null);
              return col === 'Import status' ? (
                <span
                  className={classNames(
                    'badge-status',
                    _.kebabCase(value as string),
                  )}
                  // Error Message
                  title={property.errors && property.errors.length > 0 ? property.errors.map(error => (error as unknown as any).message).join(', ') : ''}
                >
                  {value}
                </span>
              ) : (
                <span className={style['noWrap']}>{value}</span>
              )
            }}
          </Column>
        ))}
        <Column id='actions' label='Actions'>
          {(property: ExtProperty) => (
            <div className='operate'>
              <i
                role='button'
                className='edit'
                onClick={() =>
                  navigate(`../${url}?itemId=${property.id}`, {
                    state: location.pathname,
                  })
                }
              ></i>
              <i
                role='button'
                className='delete'
                onClick={() => {
                  confirmDelete();
                  setDeleteItem(property);
                }}
              ></i>
            </div>
          )}
        </Column>
        {/* Status filter */}
        <Column
          id='status'
          hidden='always'
          label={['Status', 'Import Status']}
          filterable
          editor={{
            type: InputType.Checkbox,
            options: options['status'] ? options['status'] : [],
          }}
        />
        {/* Company name filter */}
        <Column
          id='companyName'
          hidden='always'
          label={['Company', 'Select one or more companies']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['company'] ? options['company'] : [],
            getLabel: (x) => `${_.get(x, 'name')} | ${_.get(x, 'number')}`,
            getValue: (x) => _.get(x, 'name'),
            multi: true,
          }}
        />
        {/* Legal Entity filter */}
        <Column
          id='legalEntityName'
          hidden='always'
          label={['Legal Entity', 'Select one or more legal entities']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['legalEntity'] ? options['legalEntity'] : [],
            getLabel: (x) => `${_.get(x, 'name')} | ${_.get(x, 'number')}`,
            getValue: (x) => _.get(x, 'name'),
            multi: true,
          }}
        />
        <Column
          id='address.stateId'
          hidden='always'
          label={['State', 'Select one or more states']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['state'] ? options['state'] : [],
            getLabel: (x) =>
              `${(x as State).name}(${(x as State).abbreviation})`,
            getValue: (x) => (x as State).id,
            multi: true,
          }}
        />
        <Column
          id='number'
          hidden='always'
          label={['Property', 'Select one or more property numbers']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['property'] ? options['property'] : [],
            multi: true,
          }}
        />
        <Column
          id='assessorAccounts[0].number'
          hidden='always'
          label={['Account', 'Select one or more account numbers']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['account'] ? options['account'] : [],
            multi: true,
          }}
        />
        <Column
          id='assessorAccounts[0].assessorName'
          hidden='always'
          label={['Assessor', 'Select one or more assessor names']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['assessor'] ? options['assessor'] : [],
            multi: true,
          }}
        />
      </Table>
      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'comfirm'}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Confirm'
            >
              <button
                className='primary-button'
                onClick={(e) => confirm(e)}
                ref={ref}
              >
                Confirm
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Cancel'
            >
              <button
                className='default-button'
                onClick={() => dispatch({type: 'CLOSE'})}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(ValidatePropertiesTable);
