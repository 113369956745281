import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../../../components/FieldInput/FieldInput';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import FieldDate from '../../../components/FieldDate/FieldDate';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import validateEmpty from '../../../utils/validateEmpty';
import {
  IToastMessage,
  SelectOption,
  State,
  ILegalEntity,
  IErrorBase,
  ReduxState,
  NextLocation,
} from '../../../interface';
import {
  addLegalEntities,
  getCompanies,
  getLegalEntity,
  updateLegalEntity,
} from '../../../services';
import removeEmptyField from '../../../utils/removeEmptyField';
import style from './LegalEntitySetup.module.scss';
import {INCORPORATIONTYPE, PICKLEGALENTITYFIELD} from '../../../constants';
import {AxiosResponse} from 'axios';
import {connect} from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import ModalContext from '../../../context/ModalContext';
import {useBlocker} from '../../../hook/useBlocker';
import {
  getFirstDateOfThisYear,
  getLastDateOfThisYear,
} from '../../../utils/date';
import Loader, { LoaderIndicator } from '../../../components/Loader/Loader';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import validateEmail from '../../../utils/validateEmail';
import FieldToggleCheckbox from '../../../components/FieldToggleCheckbox/FieldToggleCheckbox';
import { useAppDispatch } from '../../../redux/store';
import { getDashboardInfo } from '../../../redux/features/dashboard-info-slice';
import {ReactComponent as SearchIcon} from '../CompanySetup/search.svg';
import Divider from '../../../components/Divider/Divider';

// Init formik values
const newInit = {
  companyId: null,
  name: '',
  number: '',
  naicsCode: '',
  sameAsCompany: false,
  mailingAddress: {
    line1: '',
    line2: '',
    city: '',
    stateId: null,
    postalCode: '',
  },
  packageReturnSameAsMailingAddress: false,
  packageReturnAddress: {
    line1: '',
    line2: '',
    city: '',
    stateId: null,
    postalCode: '',
  },
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactTitle: '',
  primaryContactEmailAddress: '',
  primaryContactPhoneNumber: '',
  federalTaxId: '',
  incorporationType: '',
  incorporationStateId: null,
  incorporateDate: '',
  fiscalYearStartDate: '',
  active: true,
};

// Define the required fields
const requiredField = [
  'companyId',
  'name',
  'number',
  'mailingAddress.line1',
  'mailingAddress.city',
  'mailingAddress.stateId',
  'mailingAddress.postalCode',
  'packageReturnAddress.line1',
  'packageReturnAddress.city',
  'packageReturnAddress.stateId',
  'packageReturnAddress.postalCode',
  'primaryContactFirstName',
  'primaryContactLastName',
  'primaryContactTitle',
];

interface Props {
  states: State[];
}

function getBreadcrumb(id: string | undefined, state: string) {
  if (id) {
    return [
      {
        value: 'Properties',
        href: '/properties?tab=Legal+Entities',
      },
      {
        value: 'Edit Legal Entity',
      },
    ];
  }
  if (
    new RegExp(/properties\?tab/).test(state) ||
    new RegExp(/dashboard(\/)?$/).test(state)
  ) {
    return [
      {
        value: 'Properties',
        href: '/properties?tab=Legal+Entities',
      },
      {value: 'Legal Entity Setup'},
    ];
  } else {
    return [
      {
        value: 'Properties',
        href: '/properties?tab=Legal+Entities',
      },
      {value: 'Property Setup', href: '/properties/propertyUpload'},
      {
        value: new RegExp(/editProperty/).test(state)
          ? 'Edit Property'
          : 'Add Property Manually',
        href: state,
      },
      {value: 'Add New Legal Entity'},
    ];
  }
}

function LegalEntitySetup(props: Props) {
  const {states} = props;
  const [block, setBlock] = useState(false);
  const {id} = useParams();
  const location = useLocation();
  const dashboardDispatch = useAppDispatch();
  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from

  const breadcrumb = getBreadcrumb(id, location.state as string);

  // Options of select fields
  const [companylist, setcompanylist] = useState<SelectOption[]>([]);
  const [statincorporation, setstatincorporation] = useState<SelectOption[]>(
    [],
  );
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Get options of select from JSON
  useEffect(() => {
    setstatincorporation(
      states.map((o: State) => ({
        value: o['id'],
        label: o['abbreviation'],
        name: o['name'],
      })),
    );
  }, [states]);
  useEffect(() => {
    getCompanies()
      .then((d) => {
        setcompanylist(
          _.map(d.data.items, (company) => ({
            value: company.id,
            label: company.name,
            name: company.name,
          })),
        );
        setFetchingCompanies(false);
      })
      .catch(() => {
        setFetchingCompanies(false);
      });
  }, []);

  const navigate = useNavigate();

  // Flag for submit or not
  const [issubmit, setissubmit] = useState<boolean>(false);
  const [submitToast, setSubmitToast] = useState<boolean>(false);
  const [initialValues, setinitialValues] = useState([newInit]);

  function handleResponse(promise: Promise<AxiosResponse>) {
    return promise
      .then((e: AxiosResponse<IErrorBase>) => {
        if (_.inRange(e.status, 200, 299)) {
          dashboardDispatch(getDashboardInfo());
          const redirectUrl: string = location?.state ? (location?.state as string) : '/properties?tab=Legal+Entities';
          navigate(redirectUrl);
        } else {
          setToastMessage({
            visible: true,
            message: `Error occurs, please try again later.`,
            type: 'error',
          });
        }
      })
      .catch((e) => {
        setToastMessage({
          visible: true,
          message: e?.data.errors.length
            ? e?.data.errors[0].replace(/items\.\d\./, '')
            : `Error occurs, please try again later.`,
          type: 'error',
        });
      })
      .finally(() => setSubmitting(false));
  }

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => {
      // Validate form,if Same As Mailing Address checkbox is checked,not validate the package fields
      //If Same As Company checkbox is checked,not validate the hide fields
      if (issubmit) {
        const errors = _.map(values, (value) => {
          let _required = _.cloneDeep(requiredField);
          if (value.sameAsCompany) {
            _required = _.without(
              _required,
              'mailingAddress.line1',
              'mailingAddress.city',
              'mailingAddress.stateId',
              'mailingAddress.postalCode',
              'packageReturnAddress.line1',
              'packageReturnAddress.city',
              'packageReturnAddress.stateId',
              'packageReturnAddress.postalCode',
              'primaryContactFirstName',
              'primaryContactLastName',
              'primaryContactTitle',
            );
          }
          if (value.packageReturnSameAsMailingAddress) {
            _required = _.without(
              _required,
              'packageReturnAddress.line1',
              'packageReturnAddress.city',
              'packageReturnAddress.stateId',
              'packageReturnAddress.postalCode',
            );
          }
          return _.defaults(
            validateEmpty(_.pick(value, _required)),
            values[0].primaryContactEmailAddress &&
              validateEmail({
                primaryContactEmailAddress:
                  values[0].primaryContactEmailAddress,
              }),
          );
        }) as [];
        const result = _.every(errors, (error) => {
          return _.every(_.values(error), (e: string | []) => {
            if (_.isObject(e)) {
              return _.every(_.values(e), (se) => {
                return _.every(se, _.isEmpty);
              });
            } else {
              return _.every(e, _.isEmpty);
            }
          });
        });
        if (!result) {
          if (submitToast) {
            setSubmitToast(false);
            setToastMessage({
              visible: true,
              message:
                'The Owner you save had some errors that need to be fixed. Please address these to move forward.',
              type: 'error',
            });
          }
          return errors;
        }
      }
    },
    onSubmit: () => {
      setBlock(false);
      const formValues = _.map(formik.values, (values) => {
        if (values.sameAsCompany) {
          return _.pick(
            values,
            'companyId',
            'name',
            'number',
            'sameAsCompany',
            'active',
          );
        }

        const res = {
          ...values,
          fiscalYearStartDate: values.fiscalYearStartDate
            ? moment(values.fiscalYearStartDate).format('MM/DD')
            : null,
          naicsCode: !formik.values[0].naicsCode
            ? null
            : _.toNumber(formik.values[0].naicsCode),
        };

        return values.packageReturnSameAsMailingAddress
          ? _.omit(res, 'packageReturnAddress')
          : res;
      });

      if (id) {
        setSubmitting(true);
        handleResponse(updateLegalEntity(id, formValues[0]));
      } else {
        setSubmitting(true);

        handleResponse(
          addLegalEntities({
            items: removeEmptyField(formValues).map((value) =>
              _.pick(value, PICKLEGALENTITYFIELD),
            ),
          }),
        );
      }
    },
  });

  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [fetchingLegalEntity, setFetchingLegalEntity] = useState(false);

  useEffect(() => {
    if (id) {
      setFetchingLegalEntity(true);
      getLegalEntity(id)
        .then((d) => {
          if (!d.data.packageReturnAddress) {
            _.assign(d.data, {
              packageReturnAddress: {
                line1: null,
                city: null,
                stateId: 0,
                postalCode: null,
              },
            });
          }
          if (!d.data.mailingAddress) {
            _.assign(d.data, {
              mailingAddress: {
                line1: null,
                city: null,
                stateId: 0,
                postalCode: null,
              },
            });
          }
          setinitialValues([d.data]);
          setFetchingLegalEntity(false);
        })
        .catch(() => {
          setFetchingLegalEntity(false);
        });
    }
  }, [id]);

  //Leave Guard
  const [nextPathName, setNextPathName] = useState<string | null>();
  const {state, dispatch} = useContext(ModalContext);
  const nav = useCallback((nextLocation: NextLocation) => {
    setNextPathName(nextLocation.location?.pathname + nextLocation.location?.search);
    dispatch({type: 'OPEN', href: 'comfirm-leave'});
  }, []);

  useEffect(() => {
    setBlock(!_.isEqual(initialValues, formik.values));
  }, [initialValues, formik.values]);
  useBlocker(nav, block);

  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
  }, [state.isOpen]);

  useEffect(() => {
    if (_.isEqual(companylist.length, 1)) {
      _.forEach(formik.values, (__, index) => {
        formik.setFieldValue(`${[index]}.companyId`, companylist[0].value);
      });
    }
  }, [companylist]);

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {toastMessage?.visible && (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className='validate-error'
              visiableHandler={(value) =>
                setToastMessage({...toastMessage, visible: value})
              }
            />
          </div>
        )}
        <h2>
          {' '}
          {id ? (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Edit Legal Entity'
            >
              <span>Edit Legal Entity</span>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Add New Legal Entity'
            >
              <span>Add New Legal Entity</span>
            </TooltipWrapper>
          )}
        </h2>
        <form className={style['main']} onSubmit={formik.handleSubmit}>
          {/* Owner form list */}
          {formik.initialValues && formik.initialValues.map((__: ILegalEntity, index: number) => (
            <div key={index} className={classNames(style['section'])}>
              {/* Form header,remove icon only appear when form count > 1 */}
              <div className={style['header']}>
                <h3>
                  {formik.values[index].name || formik.values[index].number
                    ? formik.values[index].name +
                      ' ' +
                      formik.values[index].number
                    : `Owner ${index + 1}`}
                </h3>
                <div className={style['operate']}>
                  <FieldToggleCheckbox
                    label='Active'
                    value={formik.values[index].active}
                    onChange={(value) =>
                      formik.setFieldValue(`${[index]}.active`, value)
                    }
                    tooltipText='Legal Entity Active'
                  />
                </div>
              </div>
              <div className={style['form']}>
                {/* Owner Information Fieldset */}
                <fieldset className={style['owner-infor']}>
                  <legend>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Legal Entity Information'
                    >
                      <span>Legal Entity Information</span>
                    </TooltipWrapper>
                  </legend>
                  <div className={style['row']}>
                    <div
                      className={classNames(
                        style['column'],
                        style['column-up-checkbox'],
                      )}
                    >
                      <FieldSelect
                        labelText='Company Name'
                        selectId={formik.values[index].companyId}
                        options={companylist}
                        onSelect={(value) =>
                          formik.setFieldValue(
                            `${[index]}.companyId`,
                            value?.value ? _.toNumber(value?.value) : null,
                          )
                        }
                        error={
                          formik.errors[index] &&
                          (formik.errors[index] as FormikValues).companyId
                        }
                        required
                      />
                    </div>
                    <div
                      className={classNames(
                        style['column'],
                        style['column-up-checkbox'],
                      )}
                    >
                      <FieldInput
                        labelText='Legal Entity Name'
                        name='name'
                        value={formik.values[index].name}
                        onChange={(value) =>
                          formik.setFieldValue(`${[index]}.name`, value)
                        }
                        maxLength={75}
                        error={
                          formik.errors[index] &&
                          (formik.errors[index] as FormikValues).name
                        }
                        required
                      />
                    </div>
                    <div
                      className={classNames(
                        style['column'],
                        style['column-up-checkbox'],
                      )}
                    >
                      <FieldInput
                        labelText='Legal Entity Number'
                        name='number'
                        value={formik.values[index].number}
                        onChange={(value) =>
                          formik.setFieldValue(`${[index]}.number`, value)
                        }
                        maxLength={50}
                        error={
                          formik.errors[index] &&
                          (formik.errors[index] as FormikValues).number
                        }
                        required
                      />
                    </div>
                    
                  </div>
                  <div className={style['custom-checkbox']}>
                    <CustomCheckbox
                      label='All the data here is the same as company'
                      value={formik.values[index].sameAsCompany}
                      onChange={(value) =>
                        formik.setFieldValue(`${[index]}.sameAsCompany`, value)
                      }
                    />
                  </div>
                </fieldset>
                {/* All below fields is hidden,when Same As Company checkbox is checked */}
                {!formik.values[index].sameAsCompany && (
                  <>
                    <Divider className={style.divider1} />
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldInput
                          labelText='NAICS Code'
                          name='naicsCode'
                          placeholder='Start Typing…'
                          value={
                            _.isNull(formik.values[index].naicsCode)
                              ? ''
                              : formik.values[index].naicsCode
                          }
                          onChange={(value) => {
                            return /^\d*$/.test(value) &&
                            formik.setFieldValue(`${[index]}.naicsCode`, value);
                          }
                            
                          }
                          icon={<SearchIcon />}
                        />
                        <p className={style['note']}>
                          Don’t know your code?{' '}
                          <a
                            href={`https://www.census.gov/naics/?year=2022&input=${formik.values[index].naicsCode}&details=${formik.values[index].naicsCode}`}
                            className='primary-link'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Find yours here
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* Mailing Address Fieldset */}
                    <fieldset>
                      <legend>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Common}
                          tooltipKey='Mailing Address'
                        >
                          <span>Mailing Address</span>
                        </TooltipWrapper>
                      </legend>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Address Line 1'
                            name='mailingAddress.line1'
                            value={formik.values[index].mailingAddress?.line1}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.mailingAddress.line1`,
                                value,
                              )
                            }
                            maxLength={100}
                            error={
                              formik.errors[index] &&
                              (formik.errors[index] as FormikValues)
                                .mailingAddress?.line1
                            }
                            required
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Address Line 2'
                            name='mailingAddress.line2'
                            value={formik.values[index].mailingAddress?.line2}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.mailingAddress.line2`,
                                value,
                              )
                            }
                            maxLength={100}
                          />
                        </div>
                        <div className={style['column']}>
                          <div className={style['address-row']}>
                            <div className={style['address-column']}>
                              <FieldInput
                                labelText='City'
                                name='mailingAddress.city'
                                value={
                                  formik.values[index].mailingAddress?.city
                                }
                                onChange={(value) =>
                                  formik.setFieldValue(
                                    `${[index]}.mailingAddress.city`,
                                    value,
                                  )
                                }
                                maxLength={50}
                                error={
                                  formik.errors[index] &&
                                  (formik.errors[index] as FormikValues)
                                    .mailingAddress?.city
                                }
                                required
                              />
                            </div>
                            <div className={style['address-column']}>
                              <FieldSelect
                                labelText='State'
                                options={statincorporation}
                                selectId={
                                  formik.values[index].mailingAddress?.stateId
                                }
                                onSelect={(value) =>
                                  formik.setFieldValue(
                                    `${[index]}.mailingAddress.stateId`,
                                    value?.value ? value?.value : null,
                                  )
                                }
                                error={
                                  formik.errors[index] &&
                                  (formik.errors[index] as FormikValues)
                                    .mailingAddress?.stateId
                                }
                                required
                              />
                            </div>
                            <div className={style['address-column']}>
                              <FieldInput
                                labelText='Postal Code'
                                name='mailingAddress.postalCode'
                                value={
                                  formik.values[index].mailingAddress
                                    ?.postalCode
                                }
                                onChange={(value) =>
                                  formik.setFieldValue(
                                    `${[index]}.mailingAddress.postalCode`,
                                    value,
                                  )
                                }
                                maxLength={10}
                                error={
                                  formik.errors[index] &&
                                  (formik.errors[index] as FormikValues)
                                    .mailingAddress?.postalCode
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {/* Package Return Address Fieldset,it will hide when Same Mailing Address chexkbox is checked */}
                    <fieldset className={style['checkbox-fieldset']}>
                      <legend>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Common}
                          tooltipKey='Package Return Address'
                        >
                          <span>Package Return Address</span>
                        </TooltipWrapper>
                      </legend>
                      <div className={style['custom-checkbox']}>
                        <CustomCheckbox
                          label='Same as Mailing Address'
                          value={
                            formik.values[index]
                              .packageReturnSameAsMailingAddress
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `${[index]}.packageReturnSameAsMailingAddress`,
                              value,
                            )
                          }
                        />
                      </div>
                      {!formik.values[index]
                        .packageReturnSameAsMailingAddress && (
                        <div className={style['row']}>
                          <div className={style['column']}>
                            <FieldInput
                              labelText='Address Line 1'
                              name='packageReturnAddress.line1'
                              value={
                                formik.values[index].packageReturnAddress?.line1
                              }
                              onChange={(value) =>
                                formik.setFieldValue(
                                  `${[index]}.packageReturnAddress.line1`,
                                  value,
                                )
                              }
                              maxLength={100}
                              error={
                                formik.errors[index] &&
                                (formik.errors[index] as FormikValues)
                                  .packageReturnAddress?.line1
                              }
                              tooltipText='Package Return Address Line 1'
                              required
                            />
                          </div>
                          <div className={style['column']}>
                            <FieldInput
                              labelText='Address Line 2'
                              name='packageReturnAddress.line2'
                              value={
                                formik.values[index].packageReturnAddress?.line2
                              }
                              onChange={(value) =>
                                formik.setFieldValue(
                                  `${[index]}.packageReturnAddress.line2`,
                                  value,
                                )
                              }
                              tooltipText='Package Return Address Line 2'
                              maxLength={100}
                            />
                          </div>
                          <div className={style['column']}>
                            <div className={style['address-row']}>
                              <div className={style['address-column']}>
                                <FieldInput
                                  labelText='City'
                                  name='packageReturnAddress.city'
                                  value={
                                    formik.values[index].packageReturnAddress
                                      ?.city
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `${[index]}.packageReturnAddress.city`,
                                      value,
                                    )
                                  }
                                  maxLength={50}
                                  error={
                                    formik.errors[index] &&
                                    (formik.errors[index] as FormikValues)
                                      .packageReturnAddress?.city
                                  }
                                  tooltipText='Package Return Address City'
                                  required
                                />
                              </div>
                              <div className={style['address-column']}>
                                <FieldSelect
                                  labelText='State'
                                  options={statincorporation}
                                  selectId={
                                    formik.values[index].packageReturnAddress
                                      ?.stateId
                                  }
                                  onSelect={(value) =>
                                    formik.setFieldValue(
                                      `${[index]}.packageReturnAddress.stateId`,
                                      value?.value ? value?.value : null,
                                    )
                                  }
                                  error={
                                    formik.errors[index] &&
                                    (formik.errors[index] as FormikValues)
                                      .packageReturnAddress?.stateId
                                  }
                                  tooltipText='Package Return Address State'
                                  required
                                />
                              </div>
                              <div className={style['address-column']}>
                                <FieldInput
                                  labelText='Postal Code'
                                  name='packageReturnAddress.postalCode'
                                  value={
                                    formik.values[index].packageReturnAddress
                                      ?.postalCode
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `${[
                                        index,
                                      ]}.packageReturnAddress.postalCode`,
                                      value,
                                    )
                                  }
                                  maxLength={10}
                                  error={
                                    formik.errors[index] &&
                                    (formik.errors[index] as FormikValues)
                                      .packageReturnAddress?.postalCode
                                  }
                                  tooltipText='Package Return Address Postal Code'
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </fieldset>
                    {/* Primary Contact Fieldset */}
                    <fieldset>
                      <legend>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Common}
                          tooltipKey='Primary Contact'
                        >
                          <span>Primary Contact</span>
                        </TooltipWrapper>
                      </legend>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Contact First Name'
                            name='primaryContactFirstName'
                            value={formik.values[index].primaryContactFirstName}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.primaryContactFirstName`,
                                value,
                              )
                            }
                            error={
                              formik.errors[index] &&
                              (formik.errors[index] as FormikValues)
                                .primaryContactFirstName
                            }
                            required
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Contact Last Name'
                            name='primaryContactLastName'
                            value={formik.values[index].primaryContactLastName}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.primaryContactLastName`,
                                value,
                              )
                            }
                            error={
                              formik.errors[index] &&
                              (formik.errors[index] as FormikValues)
                                .primaryContactLastName
                            }
                            required
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Contact Title'
                            name='primaryContactTitle'
                            value={formik.values[index].primaryContactTitle}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.primaryContactTitle`,
                                value,
                              )
                            }
                            error={
                              formik.errors[index] &&
                              (formik.errors[index] as FormikValues)
                                .primaryContactTitle
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className={style['row']}>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Contact Email Address'
                            name='primaryContactEmailAddress'
                            value={
                              formik.values[index].primaryContactEmailAddress
                            }
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.primaryContactEmailAddress`,
                                value,
                              )
                            }
                            error={
                              formik.errors[index] &&
                              (formik.errors[index] as FormikValues)
                                .primaryContactEmailAddress
                            }
                          />
                        </div>
                        <div className={style['column']}>
                          <FieldInput
                            labelText='Contact Phone Number'
                            name='primaryContactPhoneNumber'
                            value={
                              formik.values[index].primaryContactPhoneNumber
                            }
                            onChange={(value) =>
                              formik.setFieldValue(
                                `${[index]}.primaryContactPhoneNumber`,
                                value,
                              )
                            }
                          />
                        </div>
                        <div className={style['column']}></div>
                      </div>
                    </fieldset>
                    {/* Optional Information Fieldset */}
                    {formik.values[index].showOptional && (
                      <fieldset>
                        <legend>
                          <TooltipWrapper
                            tooltipSection={TOOLTIP_SECTIONS.Common}
                            tooltipKey='Optional Information'
                          >
                            <span>Optional Information</span>
                          </TooltipWrapper>
                        </legend>
                        <div className={style['row']}>
                          <div className={style['column']}>
                            <FieldInput
                              labelText='Federal Tax ID'
                              name='federalTaxId'
                              value={formik.values[index].federalTaxId}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  `${[index]}.federalTaxId`,
                                  value,
                                )
                              }
                              tooltipText='Federal Tax ID'
                            />
                          </div>
                          <div className={style['column']}>
                            <FieldSelect
                              labelText='Incorporation Type'
                              options={INCORPORATIONTYPE}
                              selectText={
                                formik.values[index].incorporationType
                              }
                              onSelect={(value) =>
                                formik.setFieldValue(
                                  `${[index]}.incorporationType`,
                                  value?.label ? value?.label : '',
                                )
                              }
                            />
                          </div>
                          <div className={style['column']}>
                            <FieldSelect
                              labelText='State of Incorporation'
                              options={statincorporation}
                              selectId={
                                formik.values[index].incorporationStateId
                              }
                              onSelect={(value) =>
                                formik.setFieldValue(
                                  `${[index]}.incorporationStateId`,
                                  value?.value ? _.toNumber(value?.value) : null,
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className={style['row']}>
                          <div className={style['column']}>
                            <div className={style['row-date']}>
                              <div className={style['column-date']}>
                                <FieldDate
                                  labelText='Incorporation Date'
                                  selectValue={
                                    formik.values[index].incorporateDate
                                  }
                                  onSelect={(value) =>
                                    formik.setFieldValue(
                                      `${[index]}.incorporateDate`,
                                      value,
                                    )
                                  }
                                />
                              </div>
                              <div className={style['column-date']}>
                                <FieldDate
                                  labelText='Fiscal Year Start Date'
                                  primaryFormat='MM/DD'
                                  minDate={getFirstDateOfThisYear()}
                                  maxDate={getLastDateOfThisYear()}
                                  selectValue={
                                    formik.values[index].fiscalYearStartDate
                                  }
                                  onSelect={(value) =>
                                    formik.setFieldValue(
                                      `${[index]}.fiscalYearStartDate`,
                                      value,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className={style['column']}></div>
                          <div className={style['column']}></div>
                        </div>
                      </fieldset>
                    )}
                    <div className={style['bottom-bar']}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.PageAction}
                        tooltipKey='Optional Information fields'
                      >
                        <i
                          role='button'
                          className={classNames('link', style['toggle-link'])}
                          onClick={() =>
                            formik.setFieldValue(
                              `${[index]}.showOptional`,
                              !formik.values[index].showOptional,
                            )
                          }
                        >
                          {`${
                            formik.values[index].showOptional ? 'Hide' : 'Show'
                          } Optional Information fields`}
                        </i>
                      </TooltipWrapper>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Common}
                        tooltipKey='Required field'
                      >
                        <span className={style['required']}>* Required field</span>
                      </TooltipWrapper>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
          <div className={style['footer']}>
            {/* Submit link,it change the flag of submit */}
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save and complete'
              >
                <button
                  type='submit'
                  className='primary'
                  onClick={() => {
                    setissubmit(true);
                    setSubmitToast(true);
                  }}
                  disabled={submitting}
                >
                  Save and complete
                  {submitting ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  type='button'
                  className='button secondary'
                  disabled={submitting}
                  onClick={() => {
                    navigate(
                      location?.state
                        ? (location?.state as string)
                        : '/properties?tab=Legal+Entities',
                    );
                  }}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </form>
      </div>
      <Modal
        title='Confirmation'
        body={<p>There are unsaved changes, do you want to discard?</p>}
        isOpen={state.isOpen && state.href === 'comfirm-leave'}
        footer={
          <div className='buttons'>
            <button
              className='primary'
              onClick={() => {
                setBlock(false);
                dispatch({type: 'CLOSE'});
                setTimeout(() => {
                  nextPathName && navigate(nextPathName);
                }, 0);
              }}
              ref={ref}
            >
              Confirm
            </button>
            <button
              className='secondary'
              onClick={() => dispatch({type: 'CLOSE'})}
            >
              Cancel
            </button>
          </div>
        }
      />

      <Loader isOpen={fetchingCompanies || fetchingLegalEntity} />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(LegalEntitySetup);
